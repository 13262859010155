import { useRef, useState, useEffect, ReactElement, useContext } from "react";
import { ChatResponseData, SummaryResponseData, UpdateTitleRequest, deleteChat, deleteSummary, updateChat, updateSummary } from "../../api";
import { DateTime } from "luxon";
import styles from "./HistorySideMenu.module.css";
import { Button, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, Typography, useDesignSystem } from "@colorkrew/design-system";
import { ChatBubbleOutline, Delete, Edit, FormatListBulletedOutlined } from "@colorkrew/design-system/dist/icons";
import { TextField } from "@mui/material";
import { useLocation } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { trans } from "../../util/trans";
import { globalNavigate } from "../../components/GlobalNavigate/GlobalNavigate";
import Loading from "../../components/Loading/Loading";
import { UserContext } from "../../contexts/UserContext";

export const enum FilterType {
    ALL,
    CHAT,
    SUMMARY
}

type HistoryItem = {
    id: string;
    title: string;
    type: FilterType;
    date: DateTime;
};

type Props = {
    loading: boolean;
    currentItemId: string | undefined;
    chats: ChatResponseData[];
    summaries: SummaryResponseData[];
    newChat: () => void;
    newSummary: () => void;
    onChatClick: (id: string) => void;
    onSummaryClick: (id: string) => void;
    updateChats: () => void;
    updateSummaries: () => void;
};

const PREVIOUS_7_DAYS = trans.get("previous_7_days");
const PREVIOUS_30_DAYS = trans.get("previous_30_days");
const OVER_1_YEAR_AGO = trans.get("over_1_year_ago");

const HistorySideMenu = ({
    loading,
    currentItemId,
    chats,
    summaries,
    newChat,
    newSummary,
    onChatClick,
    onSummaryClick,
    updateChats,
    updateSummaries
}: Props): ReactElement => {
    const location = useLocation();

    const [filter, setFilter] = useState<FilterType>(FilterType.ALL);
    const [editedItemId, setEditedItemId] = useState<string | undefined>();
    const [editedItemTitle, setEditedItemTitle] = useState<string | undefined>();
    const [deleteItem, setDeleteItem] = useState<HistoryItem | undefined>();
    const [allItems, setAllItems] = useState<Map<string, HistoryItem[]>>(new Map());
    const currTextFieldRef = useRef<HTMLInputElement>(null);

    const { tokens } = useDesignSystem();
    const userCtx = useContext(UserContext);

    useEffect(() => {
        if (currTextFieldRef && currTextFieldRef.current) {
            currTextFieldRef.current.focus();
        }
    }, [editedItemId]);

    useEffect(() => {
        const today = DateTime.now().startOf("day");
        const minus7Days = today.minus({ day: 7 });
        const minus30Days = today.minus({ day: 30 });
        const minus1Year = today.minus({ year: 1 });
        const temp7Days: HistoryItem[] = [];
        const temp30Days: HistoryItem[] = [];
        const tempOver1Year: HistoryItem[] = [];
        const tempItems: Map<string, HistoryItem[]> = new Map();

        tempItems.set(PREVIOUS_7_DAYS, temp7Days);
        tempItems.set(PREVIOUS_30_DAYS, temp30Days);

        const tempHistoryItems: HistoryItem[] = mergeLists(chats, summaries);

        for (const historyItem of tempHistoryItems) {
            if (historyItem.date >= minus7Days) {
                // Previous 7 days
                temp7Days.push(historyItem);
            } else if (historyItem.date >= minus30Days) {
                // Previous 30 days
                temp30Days.push(historyItem);
            } else if (historyItem.date >= minus1Year) {
                // Previous year
                const month = historyItem.date.monthLong;
                if (month) {
                    if (month in tempItems.keys()) {
                        tempItems.get(month)?.push(historyItem);
                    } else {
                        tempItems.set(month, [historyItem]);
                    }
                }
            } else {
                // Over 1 year
                tempOver1Year.push(historyItem);
            }
        }
        // Maps retain the order the keys are set so this is set last
        tempItems.set(OVER_1_YEAR_AGO, tempOver1Year);
        setAllItems(tempItems);
    }, [chats, summaries]);

    const handleMenuClick = (item: HistoryItem) => {
        switch (item.type) {
            case FilterType.CHAT:
                globalNavigate(`/chat/${item.id}`);
                onChatClick(item.id);
                break;
            case FilterType.SUMMARY:
                globalNavigate(`/summary/${item.id}`);
                onSummaryClick(item.id);
                break;
        }
    };

    const handleEditTitleSubmit = async (item: HistoryItem) => {
        // Optimistically update item title
        if (editedItemTitle && editedItemTitle !== item.title) {
            item.title = editedItemTitle;

            const request: UpdateTitleRequest = {
                id: item.id,
                title: editedItemTitle
            };
            // TODO: Handle error handling
            switch (item.type) {
                case FilterType.CHAT:
                    await updateChat(request);
                    updateChats();
                    break;
                case FilterType.SUMMARY:
                    await updateSummary(request);
                    updateSummaries();
                    break;
            }
        }

        setEditedItemId(undefined);
        setEditedItemTitle(undefined);
    };

    const handleDeleteItem = async () => {
        // TODO: Handle error handling
        if (deleteItem) {
            switch (deleteItem.type) {
                case FilterType.CHAT:
                    const deleteChatResp = await deleteChat(deleteItem.id);
                    if (deleteChatResp.id) {
                        if (deleteChatResp.id === currentItemId) {
                            newChat();
                        }
                        updateChats();
                    }
                    break;
                case FilterType.SUMMARY:
                    const deleteSummaryResp = await deleteSummary(deleteItem.id);
                    if (deleteSummaryResp.id) {
                        if (deleteSummaryResp.id === currentItemId) {
                            newSummary();
                        }
                        updateSummaries();
                    }
                    break;
            }
        }
    };

    const mergeLists = (chats: ChatResponseData[], summaries: SummaryResponseData[]): HistoryItem[] => {
        const mergedList: HistoryItem[] = [];
        for (const c of chats) {
            mergedList.push({ id: c.id, title: c.title, type: FilterType.CHAT, date: DateTime.fromISO(c.updated_at) });
        }

        for (const s of summaries) {
            mergedList.push({ id: s.id, title: s.title, type: FilterType.SUMMARY, date: DateTime.fromISO(s.updated_at) });
        }
        return mergedList.sort((a: HistoryItem, b: HistoryItem): number => {
            if (a.date > b.date) return -1;
            if (a.date < b.date) return 1;
            return 0;
        });
    };

    const generateListItems = (items: HistoryItem[], label: string) => {
        const filteredItems = items.filter(item => item.type === filter || filter === FilterType.ALL);
        if (filteredItems.length === 0) {
            return null;
        }
        const itemElements = filteredItems.map(item => (
            <div
                key={item.id}
                style={item.id === currentItemId ? { backgroundColor: tokens.ThemeSurfaceMid } : {}}
                className={item.id !== editedItemId ? styles.listItemContainer : ""}
            >
                <MenuItem
                    onClick={() => {
                        if (!editedItemId) {
                            handleMenuClick(item);
                        }
                    }}
                >
                    <div className={styles.listItem}>
                        {item.type === FilterType.SUMMARY && <FormatListBulletedOutlined fontSize="small" />}
                        {item.type === FilterType.CHAT && <ChatBubbleOutline fontSize="small" />}
                        {item.id === editedItemId ? (
                            // TODO: update to use design system after onBlur and inputRef properties are added
                            <TextField
                                inputRef={currTextFieldRef}
                                value={editedItemTitle ?? item.title}
                                onChange={e => setEditedItemTitle(e.target.value)}
                                onKeyDown={e => {
                                    if (e.key === "Enter") {
                                        handleEditTitleSubmit(item);
                                        if (currTextFieldRef && currTextFieldRef.current) {
                                            currTextFieldRef.current.blur();
                                        }
                                    }
                                }}
                                InputProps={{ onBlur: () => handleEditTitleSubmit(item) }}
                                spellCheck={false}
                            ></TextField>
                        ) : (
                            <div className={styles.listItemTitle}>{item.title}</div>
                        )}

                        <div className={styles.listItemButtons}>
                            <IconButton
                                color="primary"
                                onClick={e => {
                                    e.stopPropagation();
                                    setEditedItemId(item.id);
                                }}
                            >
                                <Edit fontSize="small" />
                            </IconButton>
                            <IconButton
                                color="primary"
                                onClick={e => {
                                    e.stopPropagation();
                                    setDeleteItem(item);
                                }}
                            >
                                <Delete fontSize="small" />
                            </IconButton>
                        </div>
                    </div>
                </MenuItem>
            </div>
        ));
        return (
            <>
                <div style={{ color: tokens.ThemeForegroundLow, backgroundColor: tokens.ThemeSurfacePrimary }} className={styles.itemSectionHeader}>
                    {label}
                </div>
                {itemElements}
            </>
        );
    };

    const organizeItems = () => {
        const contentList = [];
        for (const [label, items] of allItems.entries()) {
            if (items) {
                contentList.push(<div key={label}>{generateListItems(items, label)}</div>);
            }
        }
        return contentList;
    };

    const isEmptyState = () => {
        return (
            (filter === FilterType.ALL && summaries.length === 0 && chats.length === 0) ||
            (filter === FilterType.SUMMARY && summaries.length === 0) ||
            (filter === FilterType.CHAT && chats.length === 0)
        );
    };

    return (
        <div style={{ backgroundColor: tokens.ThemeSurfacePrimary, borderRight: `1px solid ${tokens.ThemeBorderMid}` }} className={styles.container}>
            {deleteItem && (
                <ConfirmationModal
                    handleClose={() => {
                        setDeleteItem(undefined);
                    }}
                    handleSubmit={handleDeleteItem}
                >
                    <h3>{trans.get("delete_confirmation")}</h3>
                </ConfirmationModal>
            )}
            <div className={styles.content}>
                {userCtx?.organization.chat_model && (
                    <div className={styles.chatModel}>
                        <Chip size="small" color="primary" label={"GPT " + userCtx?.organization.chat_model}></Chip>
                    </div>
                )}
                <div className={styles.actionContainer}>
                    <div className={styles.button}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            uppercase={false}
                            onClick={newChat}
                            disabled={location.pathname.endsWith("/chat")}
                        >
                            {trans.get("new_chat")}
                        </Button>
                    </div>
                    <div className={styles.button}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            uppercase={false}
                            onClick={newSummary}
                            disabled={location.pathname.endsWith("/summary")}
                        >
                            {trans.get("new_summary")}
                        </Button>
                    </div>
                    <div className={styles.filterSelect}>
                        <Typography variant="headingS">{trans.get("history")}</Typography>
                        <FormControl fullWidth>
                            <InputLabel id="filterLabel">{trans.get("filter_by_type")}</InputLabel>
                            <Select
                                labelId="filterLabel"
                                label={trans.get("filter_by_type")}
                                value={filter}
                                onChange={e => setFilter(e.target.value as FilterType)}
                            >
                                <MenuItem value={FilterType.ALL}>{trans.get("all")}</MenuItem>
                                <MenuItem value={FilterType.CHAT}>{trans.get("chats")}</MenuItem>
                                <MenuItem value={FilterType.SUMMARY}>{trans.get("summaries")}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                {loading ? (
                    <Loading />
                ) : (
                    <div className={styles.itemList}>{isEmptyState() ? <div>{trans.get("empty_state_items")}</div> : <>{organizeItems()}</>}</div>
                )}
            </div>
        </div>
    );
};

export default HistorySideMenu;

export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda"
}

export const enum AccessRole {
    Admin = "admin",
    Normal = "normal"
}

// Ensure synced with backend enum organization_settings.ChatModel
export const enum ChatModel {
    Gpt3_5 = "3.5",
    Gpt4 = "4.0"
}

export type AutocompleteTag = {
    id: string;
    label: string;
};

export type SummaryResponseData = {
    id: string;
    user_id: string;
    organization_id: string;
    tenant_id: string;
    question: string;
    answer: string;
    file_name: string;
    file_size: string;
    title: string;
    input_tokens: number;
    output_tokens: number;
    total_tokens: number;
    created_at: string;
    updated_at: string;
};

export type User = {
    id: string;
    tenant_id: string;
    email: string;
    tenant_role: string;
    organization_role: string;
    first_name: string;
    last_name: string;
    avatar_url: string;
    profile_url: string;
    organization_id: string;
    suspended_until?: string;
};

export type BaseModel = { id: string; name: string };

export interface Organization extends BaseModel {
    tenant_id: string;
    chat_model?: string;
}

export interface TokenLimit {
    id: string;
    tenant_id: string;
    organization_id: string;
    organization_name?: string;
    user_id?: string;
    first_name?: string;
    last_name?: string;
    requests?: number;
    token_limit?: number;
    current_tokens: number;
    month: number;
    year: number;
    chat_model: ChatModel;
    suspended_until: string | null;
    created_at: string;
    updated_at: string;
}

export interface TenantAdminDetails {
    org_count: number;
    user_count: number;
    requests: number;
    total_tokens: number;
    chat_tokens: number;
    chat4_tokens: number;
}

export interface TenantAdminOrgDetails extends TokenLimit {
    user_count: number;
}

export type BaseResponse = {
    error?: string;
    message?: string;
};

export interface TaskStatusResponse extends BaseResponse {
    summary?: SummaryResponseData;
}

export interface TaskResponse extends BaseResponse {
    task_id?: string;
}

export type AskRequestOverrides = {
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
};

export type AskRequest = {
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export interface AskResponse extends BaseResponse {
    answer: string;
    thoughts: string | null;
    data_points: string[];
    topic_id: string;
}

export type ChatRequest = {
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
    chatId?: string;
    parent?: string;
};

export interface ChatMessageResponse extends BaseResponse {
    data?: { question_id: string; answer_id: string; chat: ChatResponseData };
}

export interface ChatResponse extends BaseResponse {
    chat?: ChatResponseData;
}

export interface AllChatResponse extends BaseResponse {
    chats?: ChatResponseData[];
}

export type ChatResponseData = {
    id: string;
    user_id: string;
    organization_id: string;
    tenant_id: string;
    title: string;
    history: { [key: string]: MessageResponseData };
    created_at: string;
    updated_at: string;
    root_node: string;
    current_node: string;
};

export type MessageResponseData = {
    id: string;
    user_id: string;
    organization_id: string;
    tenant_id: string;
    chat_id: string;
    parent: string;
    children: string[];
    role: string;
    content: string;
    keyword_input_tokens: number;
    keyword_output_tokens: number;
    answer_input_tokens: number;
    answer_output_tokens: number;
    created_at: string;
    ip_address: string;
};

export interface DeleteResponse extends BaseResponse {
    id?: string;
}

export interface LoginResponse extends BaseResponse {
    is_logged_in: boolean;
    authorization_url?: string;
}

export interface LogoutResponse extends BaseResponse {
    is_logged_in: boolean;
}

export type SummaryFileRequest = {
    file: File;
    fileSize: number;
    customPrompt?: string;
    depth: number;
};

export type SummaryRequest = {
    question: string;
    customPrompt?: string;
    depth: number;
};

export interface SummaryResponse extends BaseResponse {
    summary?: SummaryResponseData;
}

export interface AllSummaryResponse extends BaseResponse {
    summaries?: SummaryResponseData[];
}

export type UpdateTitleRequest = {
    id: string;
    title: string;
};

export type TeamSwitchRequest = {
    organization_id: string;
    tenant_id: string;
};

export interface UserDetailsResponse extends BaseResponse {
    user: User;
    tenant: BaseModel;
    organization: Organization;
    organizations: Organization[];
}

export interface TeamAdminResponse extends BaseResponse {
    organization: TokenLimit;
    users: TokenLimit[];
}

export interface TenantAdminResponse extends BaseResponse {
    tenant: TenantAdminDetails;
    organizations: TenantAdminOrgDetails[];
}

export type ToggleServiceRequest = {
    limit_ids: string[];
    suspend_until?: string;
};
export interface ToggleServiceResponse extends BaseResponse {
    successful_ids?: string[];
    failed_ids?: string[];
}

import { ReactElement, useEffect, useState } from "react";
import styles from "./TenantAdmin.module.css";
import {
    Alert,
    Autocomplete,
    Button,
    Checkbox,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useDesignSystem
} from "@colorkrew/design-system";
import { ArrowDropDown, AutoAwesome, CheckBox, CheckBoxOutlineBlank, InfoOutlined } from "@colorkrew/design-system/dist/icons";
import { LanguageEnum, trans } from "../../util/trans";
import {
    AutocompleteTag,
    ChatModel,
    TenantAdminDetails,
    TenantAdminOrgDetails,
    TokenLimit,
    downgradeGpt,
    expandTokens,
    restoreService,
    upgradeGpt
} from "../../api";
import { formatNumber, isDateInFuture } from "../../util/helpers";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import SuspendServiceSettingsModal from "../../components/Admin/SuspendServiceSettingsModal";
import { EntityType } from "./TeamAdmin";
import { DateTime } from "luxon";
import { Circle, MoreVert } from "@colorkrew/design-system/dist/icons";
import { ButtonGroup, TableSortLabel, Tooltip } from "@mui/material";
import { SortOrder } from "../../common/models";

const enum ActionType {
    EXPAND,
    UPGRADE,
    DOWNGRADE,
    SUSPEND,
    RESTORE
}

interface HeaderCell {
    id: keyof TenantAdminOrgDetails;
    label: string;
    numeric: boolean;
}

const headerCells: readonly HeaderCell[] = [
    {
        id: "organization_name",
        label: trans.get("organization"),
        numeric: false
    },
    {
        id: "user_count",
        label: trans.get("users"),
        numeric: true
    },
    {
        id: "requests",
        label: trans.get("requests"),
        numeric: true
    },
    {
        id: "current_tokens",
        label: trans.get("token_usage"),
        numeric: true
    },
    {
        id: "token_limit",
        label: trans.get("tokens"),
        numeric: true
    },
    {
        id: "chat_model",
        label: trans.get("gpt_version"),
        numeric: true
    },
    {
        id: "suspended_until",
        label: trans.get("status"),
        numeric: false
    }
];
type Props = {
    tenant: TenantAdminDetails;
    orgLimitDetails: TenantAdminOrgDetails[];
    setOrgLimitDetails: (details: TenantAdminOrgDetails[]) => void;
    setOrganization: (orgId: string, chatModel: ChatModel) => void;
};

const TenantAdmin = ({ tenant, orgLimitDetails, setOrgLimitDetails, setOrganization }: Props): ReactElement => {
    const [selectedOrgLimitIds, setSelectedOrgLimitIds] = useState<Set<string>>(new Set<string>());

    const [orgLimitMenuAnchor, setOrgLimitMenuAnchor] = useState<HTMLElement | null>(null);
    const [batchMenuAnchor, setBatchMenuAnchor] = useState<HTMLElement | null>(null);
    const [menuId, setMenuId] = useState<string | null>(null);

    const [sortBy, setSortBy] = useState<keyof TenantAdminOrgDetails | null>(null);
    const [sortOrder, setSortOrder] = useState(SortOrder.ASCENDING);

    const [error, setError] = useState<string | null>(null);

    const [viewableOrgs, setViewableOrgs] = useState<TenantAdminOrgDetails[]>(orgLimitDetails);
    const [selectedOptions, setSelectedOptions] = useState<AutocompleteTag[]>([]);

    const [actionConfirmOpen, setActionConfirmOpen] = useState<boolean>(false);
    const [selectedAction, setSelectedAction] = useState<ActionType | null>(null);
    const [selectedOrgLimitId, setSelectedOrgLimitId] = useState<string | null>(null);

    const { tokens } = useDesignSystem();
    const borderStyle = `1px solid ${tokens.ThemeBorderMid}`;

    useEffect(() => {
        setViewableOrgs(orgLimitDetails.filter(detail => viewableOrgs.map(org => org.id).includes(detail.id)));
    }, [orgLimitDetails]);

    const selectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedOrgLimitIds(new Set<string>(viewableOrgs.map(org => org.id)));
            return;
        }
        setSelectedOrgLimitIds(new Set<string>());
    };

    const handleOrgMenuClick = (event: React.MouseEvent<HTMLElement>, limitId: string) => {
        setOrgLimitMenuAnchor(event.currentTarget);
        setMenuId(limitId);
    };
    const handleOrgMenuClose = () => {
        setOrgLimitMenuAnchor(null);
        setMenuId(null);
    };
    const handleActionSelect = (action: ActionType, limitId: string) => {
        setError(null);
        setSelectedAction(action);
        setSelectedOrgLimitId(limitId);
        handleOrgMenuClose();
        // Suspend action has separate modal
        if (action !== ActionType.SUSPEND) {
            setActionConfirmOpen(true);
        }
    };
    const handleBatchActionSelect = (action: ActionType) => {
        setSelectedAction(action);
        setActionConfirmOpen(true);
        setBatchMenuAnchor(null);
    };
    const handleSort = (field: keyof TenantAdminOrgDetails) => {
        let order = SortOrder.ASCENDING;
        // Toggle sorting order if the same field is clicked
        if (sortBy === field) {
            order = sortOrder === SortOrder.ASCENDING ? SortOrder.DESCENDING : SortOrder.ASCENDING;
            setSortOrder(order);
        } else {
            setSortBy(field);
            setSortOrder(order);
        }
        setViewableOrgs(
            viewableOrgs.slice().sort((a, b) => {
                let aVal;
                let bVal;
                if (field === "suspended_until") {
                    aVal = a[field] ?? DateTime.utc().toISO();
                    bVal = b[field] ?? DateTime.utc().toISO();
                } else {
                    aVal = a[field] ?? Number.MAX_SAFE_INTEGER;
                    bVal = b[field] ?? Number.MAX_SAFE_INTEGER;
                }

                if (aVal === null) {
                    return 1;
                }
                if (bVal === null) {
                    return -1;
                }
                const comparison = aVal > bVal ? 1 : -1;
                return order === SortOrder.ASCENDING ? comparison : -comparison;
            })
        );
    };

    const updateLimits = (updatedLimitIds: string[], suspendUntil: string | null) => {
        const idSet = new Set<string>(updatedLimitIds);
        setOrgLimitDetails(orgLimitDetails.map(limit => (idSet.has(limit.id) ? { ...limit, suspended_until: suspendUntil } : limit)));
    };

    const getActionButton = (limit: TenantAdminOrgDetails) => {
        if (limit.chat_model === ChatModel.Gpt3_5) {
            return (
                <Button uppercase={false} color="primary" variant="text" onClick={() => handleActionSelect(ActionType.UPGRADE, limit.id)}>
                    {trans.get("upgrade_gpt")}
                </Button>
            );
        } else {
            return (
                <Button uppercase={false} color="primary" variant="text" onClick={() => handleActionSelect(ActionType.EXPAND, limit.id)}>
                    {trans.get("expand_tokens")}
                </Button>
            );
        }
    };

    const closeActionConfirmModal = () => {
        setSelectedOrgLimitId(null);
        setSelectedAction(null);
        setActionConfirmOpen(false);
    };

    const getActionConfirmationDetails = () => {
        const selectedIds = selectedOrgLimitId ? [selectedOrgLimitId] : Array.from(selectedOrgLimitIds.values());
        let actionConfirmHeader, actionConfirmBody, handleActionConfirm;
        switch (selectedAction) {
            case ActionType.EXPAND:
                actionConfirmHeader = trans.get("confirmation_token_expand");
                actionConfirmBody = trans.get("confirmation_body_base", { action: trans.get("confirmation_body_token_expand") });
                handleActionConfirm = () => {
                    expandTokens(selectedIds);
                };
                break;
            case ActionType.UPGRADE:
                actionConfirmHeader = trans.get("confirmation_gpt_upgrade");
                actionConfirmBody = trans.get("confirmation_body_base", { action: trans.get("confirmation_body_gpt_upgrade") });
                handleActionConfirm = () => {
                    upgradeGpt(selectedIds);
                };
                break;
            case ActionType.DOWNGRADE:
                actionConfirmHeader = trans.get("confirmation_gpt_downgrade");
                actionConfirmBody = trans.get("confirmation_body_base", { action: trans.get("confirmation_body_gpt_downgrade") });
                handleActionConfirm = () => {
                    downgradeGpt(selectedIds);
                };
                break;
            case ActionType.RESTORE:
                actionConfirmHeader = trans.get("restore_service");
                actionConfirmBody = trans.get("confirmation_restore_team");
                handleActionConfirm = () => {
                    restoreService({ limit_ids: selectedIds }).then(() => {
                        updateLimits(selectedIds, null);
                    });
                };
                break;
        }
        return { actionConfirmHeader, actionConfirmBody, handleActionConfirm };
    };

    const { actionConfirmHeader, actionConfirmBody, handleActionConfirm } = getActionConfirmationDetails();

    return (
        <>
            {/* Suspend service confirmation modal */}
            {selectedAction === ActionType.SUSPEND && (
                <SuspendServiceSettingsModal
                    limitIds={selectedOrgLimitId ? [selectedOrgLimitId] : Array.from(selectedOrgLimitIds.values())}
                    type={EntityType.TEAM}
                    setError={setError}
                    onClose={closeActionConfirmModal}
                    onConfirm={updateLimits}
                />
            )}
            {actionConfirmOpen && actionConfirmHeader && actionConfirmBody && handleActionConfirm && (
                <ConfirmationModal handleClose={closeActionConfirmModal} handleSubmit={handleActionConfirm}>
                    <div className={styles.confirmationModal}>
                        <Typography variant="headingS">{actionConfirmHeader}</Typography>
                        <Typography variant="bodyM">{actionConfirmBody}</Typography>
                    </div>
                </ConfirmationModal>
            )}
            {error && (
                <Alert severity="error" color="error">
                    {error}
                </Alert>
            )}
            {/* Usage stats dashboard */}
            <div style={{ border: `1px solid ${tokens.ThemeBorderMid}`, backgroundColor: tokens.ThemeSurfacePrimary }} className={styles.usageStatsContainer}>
                <Typography variant="headingM">{trans.get("totals")}</Typography>
                <div className={styles.usageStats}>
                    <div className={styles.usageStatDetail}>
                        <Typography variant="headingXL">{formatNumber(tenant.org_count)}</Typography>
                        <Typography variant="headingS">{trans.get("team")}</Typography>
                    </div>
                    <div className={styles.usageStatDetail}>
                        <Typography variant="headingXL">{formatNumber(tenant.user_count)}</Typography>
                        <Typography variant="headingS">{trans.get("users")}</Typography>
                    </div>
                    <div className={styles.usageStatDetail}>
                        <Typography variant="headingXL">{formatNumber(tenant.requests)}</Typography>
                        <div className={styles.usageStatSubtitle}>
                            <Typography variant="headingS">{trans.get("requests_made")}</Typography>
                            <Tooltip arrow title={trans.get("request_count_tooltip")}>
                                <InfoOutlined fontSize="small" />
                            </Tooltip>
                        </div>
                    </div>
                    <div className={styles.usageStatDetail}>
                        <Typography variant="headingXL">{formatNumber(tenant.total_tokens)}</Typography>
                        <Typography variant="headingS">{trans.get("tokens_used")}</Typography>
                    </div>
                    <div className={styles.usageStatDetail}>
                        <Typography variant="headingXL">{formatNumber(tenant.chat_tokens)}</Typography>
                        <Typography variant="headingS">{trans.get("gpt_tokens_used", { model: "3.5" })}</Typography>
                    </div>
                    <div className={styles.usageStatDetail}>
                        <Typography variant="headingXL">{formatNumber(tenant.chat4_tokens)}</Typography>
                        <Typography variant="headingS">{trans.get("gpt_tokens_used", { model: "4" })}</Typography>
                    </div>
                </div>
            </div>
            {/* Filters and batch */}
            <div className={styles.filterContainer}>
                <div style={{ backgroundColor: tokens.ThemeSurfacePrimary }} className={styles.autocomplete}>
                    <Autocomplete
                        size="small"
                        label={trans.get("filter_by_team")}
                        disableCloseOnSelect
                        multiple
                        fullWidth
                        options={[...new Map(orgLimitDetails.map(limit => [limit.organization_name, limit])).values()].map(limit => ({
                            id: limit.id,
                            label: limit.organization_name ?? ""
                        }))} // Removes duplicate organization names from the option list
                        isOptionEqualToValue={(option, value) => option?.label === value?.label}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox icon={<CheckBoxOutlineBlank fontSize="small" />} checkedIcon={<CheckBox fontSize="small" />} checked={selected} />
                                {option?.label}
                            </li>
                        )}
                        onChange={(_, value) => {
                            // Any time the filter changes, reset the selected org limit ids
                            setSelectedOrgLimitIds(new Set<string>());
                            const selectedLabels = value.map(val => val?.label ?? "");
                            setSelectedOptions(value as AutocompleteTag[]);
                            if (selectedLabels.length > 0) {
                                setViewableOrgs(
                                    orgLimitDetails.filter((limit: TenantAdminOrgDetails) => selectedLabels.includes(limit.organization_name ?? ""))
                                );
                            } else {
                                // If no ids are selected then show all org limits
                                setViewableOrgs(orgLimitDetails);
                            }
                        }}
                        limitTags={2}
                        value={selectedOptions}
                    />
                </div>
                <div>
                    <ButtonGroup disabled={selectedOrgLimitIds.size === 0}>
                        <Button variant="contained" uppercase={false} onClick={() => handleBatchActionSelect(ActionType.EXPAND)}>
                            <div className={styles.batchIcon}>
                                <AutoAwesome fontSize="small" />
                            </div>
                            <div>{trans.get("batch", { action: trans.get("expand_tokens").toLowerCase() })}</div>
                        </Button>
                        <Button variant="contained" onClick={e => setBatchMenuAnchor(e.currentTarget)}>
                            <ArrowDropDown />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={batchMenuAnchor}
                        open={Boolean(batchMenuAnchor)}
                        onClose={() => {
                            setBatchMenuAnchor(null);
                        }}
                    >
                        <MenuItem onClick={() => handleBatchActionSelect(ActionType.EXPAND)}>
                            {trans.get("batch", { action: trans.get("expand_tokens").toLowerCase() })}
                        </MenuItem>
                        <MenuItem onClick={() => handleBatchActionSelect(ActionType.UPGRADE)}>
                            {trans.get("batch", { action: trans.get("upgrade_gpt_version").toLowerCase() })}
                        </MenuItem>
                        <MenuItem onClick={() => handleBatchActionSelect(ActionType.DOWNGRADE)}>
                            {trans.get("batch", { action: trans.get("downgrade_gpt_version").toLowerCase() })}
                        </MenuItem>
                        <MenuItem onClick={() => handleBatchActionSelect(ActionType.SUSPEND)}>
                            {trans.get("batch", { action: trans.get("stop_service").toLowerCase() })}
                        </MenuItem>
                        <MenuItem onClick={() => handleBatchActionSelect(ActionType.RESTORE)}>
                            {trans.get("batch", { action: trans.get("restore_service").toLowerCase() })}
                        </MenuItem>
                    </Menu>
                </div>
            </div>
            <div style={{ border: borderStyle, backgroundColor: tokens.ThemeSurfacePrimary }} className={styles.teamDetailsTable}>
                <Table padding="normal">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    indeterminate={selectedOrgLimitIds.size > 0 && selectedOrgLimitIds.size < viewableOrgs.length}
                                    checked={viewableOrgs.length > 0 && selectedOrgLimitIds.size === viewableOrgs.length}
                                    onChange={selectAllClick}
                                    inputProps={{
                                        "aria-label": "select all team"
                                    }}
                                />
                            </TableCell>
                            {headerCells.map(cell => (
                                <TableCell key={cell.id} align={cell.numeric ? "right" : "left"} sortDirection={sortBy === cell.id ? sortOrder : false}>
                                    <TableSortLabel
                                        active={sortBy === cell.id}
                                        direction={sortBy === cell.id ? sortOrder : SortOrder.ASCENDING}
                                        onClick={() => handleSort(cell.id)}
                                    >
                                        {cell.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            <TableCell>{trans.get("actions")}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {viewableOrgs.map(org_limit => {
                            const isSelected = selectedOrgLimitIds.has(org_limit.id);
                            const isSuspended = isDateInFuture(org_limit.suspended_until);
                            const suspendedUntil = org_limit.suspended_until ? DateTime.fromISO(org_limit.suspended_until) : null;
                            let suspendedUntilText = trans.get("further_notice");
                            if (suspendedUntil) {
                                if (suspendedUntil <= DateTime.utc().plus({ years: 1 })) {
                                    suspendedUntilText =
                                        trans.getLang() === LanguageEnum.JA ? suspendedUntil.toFormat("M月d日") : suspendedUntil.toFormat("LLL dd");
                                }
                            }
                            null;
                            return (
                                <TableRow key={org_limit.id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            onChange={e => {
                                                const newSet = new Set<string>(selectedOrgLimitIds);
                                                if (e.target.checked) {
                                                    newSet.add(org_limit.id);
                                                } else {
                                                    newSet.delete(org_limit.id);
                                                }
                                                setSelectedOrgLimitIds(newSet);
                                            }}
                                            color="primary"
                                            checked={isSelected}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            uppercase={false}
                                            color="primary"
                                            variant="text"
                                            onClick={() => setOrganization(org_limit.organization_id, org_limit.chat_model)}
                                        >
                                            {org_limit.organization_name}
                                        </Button>
                                    </TableCell>
                                    <TableCell align="right">{formatNumber(org_limit.user_count)}</TableCell>
                                    <TableCell align="right">{formatNumber(org_limit.requests ?? 0)}</TableCell>
                                    <TableCell align="right">{formatNumber(org_limit.current_tokens)}</TableCell>
                                    <TableCell align="right">{org_limit.token_limit ? formatNumber(org_limit.token_limit) : "-"}</TableCell>
                                    <TableCell align="right">{org_limit.chat_model}</TableCell>
                                    <TableCell>
                                        <div
                                            style={{ color: isSuspended ? tokens.ThemeForegroundFeedbackError : tokens.ThemeForegroundFeedbackSuccess }}
                                            className={styles.status}
                                        >
                                            <Circle sx={{ fontSize: "12px" }} color={isSuspended ? "error" : "success"} />
                                            {isSuspended && suspendedUntil ? trans.get("suspended_until", { date: suspendedUntilText }) : "Active"}
                                        </div>
                                    </TableCell>
                                    <TableCell>{getActionButton(org_limit)}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={e => handleOrgMenuClick(e, org_limit.id)}>
                                            <MoreVert />
                                        </IconButton>
                                        <Menu
                                            anchorEl={orgLimitMenuAnchor}
                                            open={Boolean(orgLimitMenuAnchor && menuId === org_limit.id)}
                                            onClose={handleOrgMenuClose}
                                        >
                                            <MenuItem
                                                disabled={org_limit.token_limit !== null}
                                                onClick={() => handleActionSelect(ActionType.EXPAND, org_limit.id)}
                                            >
                                                {trans.get("expand_tokens")}
                                            </MenuItem>
                                            {org_limit.chat_model === ChatModel.Gpt3_5 && (
                                                <MenuItem onClick={() => handleActionSelect(ActionType.UPGRADE, org_limit.id)}>
                                                    {trans.get("upgrade_gpt_version")}
                                                </MenuItem>
                                            )}
                                            {org_limit.chat_model !== ChatModel.Gpt3_5 && (
                                                <MenuItem onClick={() => handleActionSelect(ActionType.DOWNGRADE, org_limit.id)}>
                                                    {trans.get("downgrade_gpt_version")}
                                                </MenuItem>
                                            )}
                                            <MenuItem
                                                disabled={isDateInFuture(org_limit.suspended_until)}
                                                onClick={() => handleActionSelect(ActionType.SUSPEND, org_limit.id)}
                                            >
                                                {trans.get("stop_service")}
                                            </MenuItem>
                                            <MenuItem
                                                disabled={!isDateInFuture(org_limit.suspended_until)}
                                                onClick={() => handleActionSelect(ActionType.RESTORE, org_limit.id)}
                                            >
                                                {trans.get("restore_service")}
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
            {viewableOrgs.length === 0 && (
                <div
                    style={{ borderBottom: borderStyle, borderLeft: borderStyle, borderRight: borderStyle, backgroundColor: tokens.ThemeSurfacePrimary }}
                    className={styles.emptyState}
                >
                    <Typography variant="headingS">{trans.get("empty_state_items")}</Typography>
                </div>
            )}
        </>
    );
};
export default TenantAdmin;

import { Typography } from "@colorkrew/design-system";
import styles from "./ErrorAlert.module.css";
import { ErrorOutline } from "@mui/icons-material";

type Props = {
    header: string;
    description: string;
};

const ErrorAlert = ({ header, description }: Props) => {
    return (
        <div className={styles.container}>
            <ErrorOutline color="error" sx={{ fontSize: "100px" }} />
            <Typography variant="headingXL">{header}</Typography>
            <div className={styles.description}>
                <Typography variant="bodyM">{description}</Typography>
            </div>
        </div>
    );
};

export default ErrorAlert;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logoutApi } from "../../api";
import Cookies from "js-cookie";

const Logout = () => {
    const navigate = useNavigate();
    useEffect(() => {
        logoutApi()
            .then(() => {
                Cookies.remove("is_logged_in");
                Cookies.remove("user_role");
                console.log("Logging out");
            })
            .finally(() => {
                navigate("/login");
            });
    }, []);

    return <></>;
};

export default Logout;

import { useEffect, useRef, useState } from "react";
import { SendOutlined } from "@colorkrew/design-system/dist/icons";

import styles from "./QuestionInput.module.css";
import { Box, InputAdornment, OutlinedInput, Tooltip, IconButton } from "@mui/material";
import { trans } from "../../util/trans";

interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const textFieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (textFieldRef && textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, []);

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && ev.ctrlKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = ev.target.value;
        if (!newValue) {
            setQuestion("");
        } else {
            setQuestion(newValue);
        }
    };

    return (
        <Box className={styles.questionBox}>
            <OutlinedInput
                inputRef={textFieldRef}
                maxRows={10}
                disabled={disabled}
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                multiline
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
                endAdornment={
                    <InputAdornment position="end">
                        <Tooltip title={trans.get("shortcut_key")} placement="top">
                            <IconButton aria-label="send icon" onClick={sendQuestion} edge="end">
                                <SendOutlined />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                }
            />
        </Box>
    );
};

import styles from "./FileUpload.module.css";
import { useDesignSystem } from "@colorkrew/design-system";
import { ReactElement, useRef, useState } from "react";
import { FileDownloadOutlined, UploadFileOutlined } from "@colorkrew/design-system/dist/icons";
import { trans } from "../../util/trans";
import { getFileSizeInMb } from "../../util/helpers";
import SelectedFile from "./SelectedFile";

const ALLOWED_EXTENSIONS = [".txt", ".docx", ".pdf"];
const MAX_FILE_LIMIT = 10485760; // 10MB

type Props = {
    disableRemove?: boolean;
    selectedFile: File | undefined;
    handleFileSelect: (file: File | undefined) => void;
    handleError: (error: string) => void;
};

const FileUpload = ({ disableRemove = false, selectedFile, handleFileSelect, handleError }: Props): ReactElement => {
    const [isDrag, setIsDrag] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const { tokens } = useDesignSystem();

    // Check if file size is less than max file size and is a compatible file type
    const validateFile = (file: File): boolean => {
        const fileName = file.name;
        const extension = fileName.split(".").at(-1);
        return file?.size <= MAX_FILE_LIMIT && ALLOWED_EXTENSIONS.includes("." + extension?.toLowerCase());
    };

    const setFileIfExists = (files: FileList | null) => {
        if (files && files.length > 0) {
            const file = files[0];

            if (validateFile(file)) {
                handleError("");
                handleFileSelect(file);
            } else {
                handleError(trans.get("error_invalid_file"));
            }
        }
    };

    const removeFile = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        handleFileSelect(undefined);
    };

    return (
        <>
            <div
                style={{
                    border: `2px dashed ${tokens.ThemeBorderMid}`,
                    backgroundColor: isDrag ? tokens.ThemeSurfaceMid : tokens.ThemeSurfacePrimary
                }}
                className={styles.fileUploadContainer}
                onDragEnter={() => {
                    setIsDrag(true);
                }}
                onDragOver={e => {
                    e.preventDefault();
                    setIsDrag(true);
                }}
                onDragLeave={() => {
                    setIsDrag(false);
                }}
                onDrop={e => {
                    e.preventDefault();
                    setIsDrag(false);
                    setFileIfExists(e.dataTransfer.files);
                }}
            >
                {isDrag ? (
                    <FileDownloadOutlined style={{ fontSize: "6em", color: tokens.ThemeForegroundLow }} />
                ) : (
                    <UploadFileOutlined style={{ fontSize: "6em", color: tokens.ThemeForegroundLow }} />
                )}
                <div className={styles.fileUploadHeader}>
                    {trans.get("file_upload_drop_prompt")} &nbsp;
                    <label htmlFor="fileUpload" style={{ color: tokens.ThemeSurfaceAccentPrimary }} className={styles.fileUploadText}>
                        {trans.get("file_upload_input_text")}
                    </label>
                    <input
                        id="fileUpload"
                        ref={fileInputRef}
                        type="file"
                        accept={ALLOWED_EXTENSIONS.join(",")}
                        className={styles.fileUpload}
                        onChange={e => {
                            setFileIfExists(e.target.files);
                        }}
                    />
                </div>
                <div style={{ color: tokens.ThemeForegroundLow }}>{trans.get("supported_files", { types: ALLOWED_EXTENSIONS.join(" ") })}</div>
                <div style={{ color: tokens.ThemeForegroundLow }}>{trans.get("max_file_size", { size: getFileSizeInMb(MAX_FILE_LIMIT) })}</div>
            </div>
            {selectedFile && (
                <SelectedFile disableRemove={disableRemove} fileName={selectedFile.name} fileSize={selectedFile.size} handleFileRemove={removeFile} />
            )}
        </>
    );
};

export default FileUpload;

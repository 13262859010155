import { useNavigate } from "react-router-dom";
import styles from "./Loading.module.css";
import { CircularProgress } from "@mui/material";

const Loading = () => {
    return (
        <div className={styles.loadingSpinner}>
            <CircularProgress />
        </div>
    );
};

export default Loading;

import { ReactElement, useEffect, useState } from "react";
import styles from "./TeamAdmin.module.css";
import {
    Alert,
    Autocomplete,
    Button,
    Checkbox,
    Chip,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useDesignSystem
} from "@colorkrew/design-system";
import { CheckBox, CheckBoxOutlineBlank } from "@colorkrew/design-system/dist/icons";
import { trans } from "../../util/trans";
import { DateTime } from "luxon";
import { AutocompleteTag, TeamAdminResponse, TokenLimit, restoreService, suspendService } from "../../api";
import Loading from "../../components/Loading/Loading";
import { formatNumber, getUserDisplayName, isDateInFuture } from "../../util/helpers";
import SuspendServiceSettingsModal from "../../components/Admin/SuspendServiceSettingsModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { SortOrder } from "../../common/models";
import { TableSortLabel } from "@mui/material";

export const enum EntityType {
    TEAM = "team",
    USER = "user"
}

const enum SortFields {
    USERNAME,
    TOKEN_USAGE,
    NUMBER_OF_REQUESTS
}

type Props = {
    teamDetails: TeamAdminResponse;
    onOrgUpdate: (id: string, suspendUntil: string | null) => void;
};

const TeamAdmin = ({ teamDetails, onOrgUpdate }: Props): ReactElement => {
    const now = DateTime.local();
    // Calculate days until next month
    const remaining_days = Math.ceil(now.startOf("month").plus({ months: 1 }).diff(now, "days").days);
    const { tokens } = useDesignSystem();
    const borderStyle = `1px solid ${tokens.ThemeBorderMid}`;

    const [error, setError] = useState<string | null>(null);
    const [currPercentage, setCurrPercentage] = useState<number>(0);
    const [isOrgPaused, setIsOrgPaused] = useState<boolean>(false);
    const [suspendLimit, setSuspendLimit] = useState<TokenLimit | null>(null);
    const [restoreLimit, setRestoreLimit] = useState<TokenLimit | null>(null);
    const [selectedEntityType, setSelectedEntityType] = useState<EntityType | null>(null);

    const allUserLimits = teamDetails.users;
    const [orgLimit, setOrgLimit] = useState<TokenLimit>(teamDetails.organization);
    const [userLimits, setUserLimits] = useState<TokenLimit[]>(allUserLimits);
    const [selectedOptions, setSelectedOptions] = useState<AutocompleteTag[]>([]);

    const [sortBy, setSortBy] = useState<SortFields | null>(null);
    const [sortOrder, setSortOrder] = useState(SortOrder.ASCENDING);

    useEffect(() => {
        if (orgLimit.token_limit) {
            setCurrPercentage(Math.round((orgLimit.current_tokens / orgLimit.token_limit) * 100));
        }
        setIsOrgPaused(isDateInFuture(orgLimit.suspended_until));
    }, [orgLimit]);

    const calcProgressColor = (percentage: number) => {
        if (isOrgPaused) {
            return "inherit";
        } else if (percentage > 80) {
            return "error";
        } else if (percentage > 50) {
            return "warning";
        } else {
            return "primary";
        }
    };

    const handleSort = (field: SortFields) => {
        let order = SortOrder.ASCENDING;
        // Toggle sorting order if the same field is clicked
        if (sortBy === field) {
            order = sortOrder === SortOrder.ASCENDING ? SortOrder.DESCENDING : SortOrder.ASCENDING;
            setSortOrder(order);
        } else {
            setSortBy(field);
            setSortOrder(order);
        }
        setUserLimits(
            userLimits.slice().sort((a, b) => {
                let aVal;
                let bVal;
                switch (field) {
                    case SortFields.USERNAME:
                        aVal = getUserDisplayName(a.first_name, a.last_name);
                        bVal = getUserDisplayName(b.first_name, b.last_name);
                        break;
                    case SortFields.TOKEN_USAGE:
                        aVal = a.current_tokens;
                        bVal = b.current_tokens;
                        break;
                    case SortFields.NUMBER_OF_REQUESTS:
                        aVal = a.requests ?? Number.MAX_SAFE_INTEGER;
                        bVal = b.requests ?? Number.MAX_SAFE_INTEGER;
                        break;
                }
                if (aVal === null) {
                    return 1;
                }
                if (bVal === null) {
                    return -1;
                }
                const comparison = aVal > bVal ? 1 : -1;
                return order === SortOrder.ASCENDING ? comparison : -comparison;
            })
        );
    };

    const getToggleServiceButtons = (limit: TokenLimit, type: EntityType, disabled: boolean = false): ReactElement => {
        return isDateInFuture(limit.suspended_until) ? (
            <Button disabled={disabled} uppercase={false} color="primary" variant="text" onClick={() => restartService(limit, type)}>
                {trans.get("restore_service")}
            </Button>
        ) : (
            <Button disabled={disabled} uppercase={false} color="error" variant="text" onClick={() => stopService(limit, type)}>
                {trans.get("stop_service")}
            </Button>
        );
    };

    const getSuspendedUntilDate = (limit: TokenLimit): string => {
        if (limit.suspended_until) {
            return DateTime.fromISO(limit.suspended_until).toFormat("LLL dd");
        }
        return "";
    };

    const updateLimits = (updatedLimitId: string, suspendUntil: string | null) => {
        if (orgLimit.id === updatedLimitId) {
            setOrgLimit({ ...orgLimit, suspended_until: suspendUntil });
            onOrgUpdate(updatedLimitId, suspendUntil);
        } else {
            setUserLimits(userLimits.map(limit => (limit.id === updatedLimitId ? { ...limit, suspended_until: suspendUntil } : limit)));
        }
    };

    const stopService = (limit: TokenLimit, type: EntityType) => {
        setError(null);
        setSuspendLimit(limit);
        setSelectedEntityType(type);
    };

    const restartService = (limit: TokenLimit, type: EntityType) => {
        setError(null);
        setRestoreLimit(limit);
        setSelectedEntityType(type);
    };

    const confirmRestartService = () => {
        if (restoreLimit) {
            restoreService({ limit_ids: [restoreLimit.id] }).then(resp => {
                updateLimits(restoreLimit.id, null);
            });
        } else {
            setError(trans.get("restore_failure"));
        }
    };

    const clearSelections = () => {
        setSuspendLimit(null);
        setSelectedEntityType(null);
        setRestoreLimit(null);
    };

    if (!orgLimit) {
        return <Loading />;
    }

    return (
        <>
            {suspendLimit && selectedEntityType && (
                <SuspendServiceSettingsModal
                    limitIds={[suspendLimit.id]}
                    type={selectedEntityType}
                    userName={getUserDisplayName(suspendLimit.first_name, suspendLimit.last_name)}
                    setError={setError}
                    onClose={clearSelections}
                    onConfirm={(limitIds: string[], suspendUntil: string) => {
                        if (limitIds.length === 0) {
                            return;
                        }
                        updateLimits(limitIds[0], suspendUntil);
                    }}
                />
            )}
            {restoreLimit && selectedEntityType && (
                <ConfirmationModal handleClose={clearSelections} handleSubmit={confirmRestartService}>
                    <div className={styles.confirmationModal}>
                        <Typography variant="headingS">{trans.get("restore_service")}</Typography>
                        <Typography variant="bodyM">
                            {selectedEntityType === EntityType.TEAM
                                ? trans.get("confirmation_restore_team", { name: restoreLimit.organization_name ?? "" })
                                : trans.get("confirmation_restore_user", { name: getUserDisplayName(restoreLimit.first_name, restoreLimit.last_name) })}
                        </Typography>
                    </div>
                </ConfirmationModal>
            )}
            {error && (
                <Alert severity="error" color="error">
                    {error}
                </Alert>
            )}
            <div className={styles.header}>
                <Typography variant="headingL">{orgLimit.organization_name}</Typography>
                <div className={styles.subheader}>
                    <Typography variant="headingS">
                        {now.monthLong}, {now.year}
                    </Typography>
                    <Chip size="small" color="primary" label={"GPT " + orgLimit.chat_model}></Chip>
                </div>
            </div>
            <div className={styles.tokenUsage}>
                <div className={styles.tokenUsageContainer}>
                    {orgLimit.token_limit ? (
                        <>
                            <div className={styles.tokenUsageHeader}>
                                <Typography variant="headingXS">
                                    {trans.get("remaining_tokens_percentage", { percent: currPercentage.toString() })}
                                    {isOrgPaused && <span className={styles.paused}>({trans.get("paused", { date: getSuspendedUntilDate(orgLimit) })})</span>}
                                </Typography>
                                <Typography variant="bodyM">
                                    {formatNumber(orgLimit.current_tokens)}/{formatNumber(orgLimit.token_limit)}
                                </Typography>
                            </div>
                            <LinearProgress color={calcProgressColor(currPercentage)} value={currPercentage} variant="determinate" />
                            <div className={styles.tokenSubtitle}>
                                <div className={styles.tokenUsageDescription}>{trans.get("token_reset_days", { days: remaining_days.toString() })}</div>
                                {getToggleServiceButtons(orgLimit, EntityType.TEAM)}
                            </div>
                        </>
                    ) : (
                        <div className={styles.tokenSubtitle}>
                            <Typography variant="headingXS">
                                {trans.get("used_tokens", { tokens: formatNumber(orgLimit.current_tokens) })}
                                {isOrgPaused && <span className={styles.paused}>({trans.get("paused", { date: getSuspendedUntilDate(orgLimit) })})</span>}
                            </Typography>
                            {getToggleServiceButtons(orgLimit, EntityType.TEAM)}
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.filterContainer}>
                <div style={{ backgroundColor: tokens.ThemeSurfacePrimary }} className={styles.autocomplete}>
                    <Autocomplete
                        size="small"
                        label={trans.get("filter_by_user")}
                        disableCloseOnSelect
                        multiple
                        fullWidth
                        options={allUserLimits.map(limit => ({ id: limit.id, label: getUserDisplayName(limit.first_name, limit.last_name) }))}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox icon={<CheckBoxOutlineBlank fontSize="small" />} checkedIcon={<CheckBox fontSize="small" />} checked={selected} />
                                {option?.label}
                            </li>
                        )}
                        onChange={(_, value) => {
                            const selectedIds = value.map(val => val?.id ?? "");
                            setSelectedOptions(value as AutocompleteTag[]);
                            if (selectedIds.length > 0) {
                                setUserLimits(allUserLimits.filter((limit: TokenLimit) => selectedIds.includes(limit.id)));
                            } else {
                                // If no ids are selected then show all user limits
                                setUserLimits(allUserLimits);
                            }
                        }}
                        limitTags={2}
                        value={selectedOptions}
                    />
                </div>
            </div>
            <div style={{ border: borderStyle, backgroundColor: tokens.ThemeSurfacePrimary }}>
                <Table padding="normal">
                    <TableHead>
                        <TableRow>
                            <TableCell sortDirection={sortBy === SortFields.USERNAME ? sortOrder : false}>
                                <TableSortLabel
                                    active={sortBy === SortFields.USERNAME}
                                    direction={sortBy === SortFields.USERNAME ? sortOrder : SortOrder.ASCENDING}
                                    onClick={() => handleSort(SortFields.USERNAME)}
                                >
                                    {trans.get("username")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right" sortDirection={sortBy === SortFields.TOKEN_USAGE ? sortOrder : false}>
                                <TableSortLabel
                                    active={sortBy === SortFields.TOKEN_USAGE}
                                    direction={sortBy === SortFields.TOKEN_USAGE ? sortOrder : SortOrder.ASCENDING}
                                    onClick={() => handleSort(SortFields.TOKEN_USAGE)}
                                >
                                    {trans.get("token_usage")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right" sortDirection={sortBy === SortFields.NUMBER_OF_REQUESTS ? sortOrder : false}>
                                <TableSortLabel
                                    active={sortBy === SortFields.NUMBER_OF_REQUESTS}
                                    direction={sortBy === SortFields.NUMBER_OF_REQUESTS ? sortOrder : SortOrder.ASCENDING}
                                    onClick={() => handleSort(SortFields.NUMBER_OF_REQUESTS)}
                                >
                                    {trans.get("number_of_requests")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>{trans.get("actions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userLimits.map(userLimit => {
                            return (
                                <TableRow key={userLimit.id}>
                                    <TableCell>{getUserDisplayName(userLimit.first_name, userLimit.last_name)}</TableCell>
                                    <TableCell align="right">{userLimit.current_tokens}</TableCell>
                                    <TableCell align="right">{userLimit.requests}</TableCell>
                                    <TableCell>{getToggleServiceButtons(userLimit, EntityType.USER, isOrgPaused)}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
            {userLimits.length === 0 && (
                <div
                    style={{ borderBottom: borderStyle, borderLeft: borderStyle, borderRight: borderStyle, backgroundColor: tokens.ThemeSurfacePrimary }}
                    className={styles.emptyState}
                >
                    <Typography variant="headingS">{trans.get("empty_state_items")}</Typography>
                </div>
            )}
        </>
    );
};
export default TeamAdmin;

import { useState, ReactElement } from "react";
import styles from "./SummarySettings.module.css";
import { Button, Slider, TextField } from "@colorkrew/design-system";
import { trans } from "../../util/trans";

export const enum SummaryDepth {
    SHORT = "short",
    MEDIUM = "medium",
    LONG = "long"
}

type Props = {
    currentDepth: number;
    currentPrompt: string;
    handleCancel: () => void;
    handleSave: (depth: number, prompt: string) => void;
};

const SummarySettings = ({ currentDepth, currentPrompt, handleCancel, handleSave }: Props): ReactElement => {
    const [depth, setDepth] = useState<number>(currentDepth);
    const [customPrompt, setCustomPrompt] = useState<string>(currentPrompt);

    return (
        <div className={styles.container}>
            <h1>{trans.get("settings")}</h1>
            <h2>{trans.get("length_of_summary")}</h2>
            <Slider
                value={depth}
                min={0}
                max={100}
                step={50}
                marks={[
                    { value: 0, label: trans.get(SummaryDepth.SHORT) },
                    { value: 50, label: trans.get(SummaryDepth.MEDIUM) },
                    { value: 100, label: trans.get(SummaryDepth.LONG) }
                ]}
                disabled={customPrompt.length > 0}
                onChange={(e, newValue) => setDepth(newValue as number)}
            />
            <h2>{trans.get("edit_prompt")}</h2>
            <TextField
                multiline
                fullWidth
                rows={6}
                placeholder={trans.get("custom_prompt_placeholder")}
                size="medium"
                type="text"
                value={customPrompt}
                onChange={e => {
                    setCustomPrompt(e.target.value);
                }}
            />
            <div className={styles.actionButtonContainer}>
                <Button uppercase={false} color="inherit" variant="contained" onClick={handleCancel}>
                    {trans.get("cancel")}
                </Button>
                <Button uppercase={false} color="primary" variant="contained" onClick={() => handleSave(depth, customPrompt)}>
                    {trans.get("save_changes")}
                </Button>
            </div>
        </div>
    );
};

export default SummarySettings;

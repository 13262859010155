import { ReactElement, SyntheticEvent, useContext, useEffect, useState } from "react";
import styles from "./Admin.module.css";
import { UserContext } from "../../contexts/UserContext";
import { globalNavigate } from "../../components/GlobalNavigate/GlobalNavigate";
import { Tabs, useDesignSystem } from "@colorkrew/design-system";
import { Tab } from "@mui/material";
import { AccessRole, ChatModel, TeamAdminResponse, TenantAdminOrgDetails, TenantAdminResponse, TokenLimit, teamAdminApi, tenantAdminApi } from "../../api";
import TenantAdmin from "./TenantAdmin";
import { appBarCkHeight } from "@colorkrew/design-system/dist/components/AppBarCk/AppBarCk";
import TeamAdmin from "./TeamAdmin";
import AppBar from "../../components/AppBar/AppBar";
import { trans } from "../../util/trans";
import Loading from "../../components/Loading/Loading";

const enum TabType {
    TEAM = "team",
    TENANT = "tenant"
}

const Admin = (): ReactElement => {
    const userCtx = useContext(UserContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [isTeamAdmin, setIsTeamAdmin] = useState<boolean>(false);
    const [isTenantAdmin, setIsTenantAdmin] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<TabType>(TabType.TENANT);
    const [currentOrgId, setCurrentOrgId] = useState<string | undefined>();
    const [orgChatModel, setOrgChatModel] = useState<ChatModel | undefined>();
    const [teamDetails, setTeamDetails] = useState<TeamAdminResponse | undefined>();
    const [tenantDetails, setTenantDetails] = useState<TenantAdminResponse | undefined>();
    const [allTeamDetails, setAllTeamDetails] = useState<TenantAdminOrgDetails[]>([]);

    useEffect(() => {
        const user = userCtx?.user;
        if (user) {
            if (user.tenant_role != AccessRole.Admin && user.organization_role != AccessRole.Admin) {
                globalNavigate("/chat");
            }
            const isTenantAdmin = user.tenant_role == AccessRole.Admin;
            setIsTeamAdmin(user.organization_role == AccessRole.Admin);
            setIsTenantAdmin(isTenantAdmin);
            if (isTenantAdmin) {
                setCurrentTab(TabType.TENANT);
            }
            setCurrentOrgId(user.organization_id);

            tenantAdminApi().then(resp => {
                setTenantDetails(resp);
                setAllTeamDetails(resp.organizations);
                setLoading(false);
            });
        }
    }, [userCtx]);

    useEffect(() => {
        if (currentOrgId) {
            teamAdminApi(currentOrgId, orgChatModel).then(resp => {
                setTeamDetails(resp);
                setLoading(false);
            });
        }
    }, [currentOrgId, orgChatModel]);

    useEffect(() => {
        if (teamDetails) {
            const updatedTeam = allTeamDetails.find(detail => detail.id === teamDetails.organization.id) as TokenLimit;
            setTeamDetails({ ...teamDetails, organization: updatedTeam });
        }
    }, [allTeamDetails]);

    const onTabChange = (_: SyntheticEvent, newValue: TabType) => {
        setCurrentTab(newValue);
    };

    const viewOrgDetails = (orgId: string, chatModel: ChatModel) => {
        if (currentOrgId !== orgId || orgChatModel !== chatModel) {
            setLoading(true);
        }
        setCurrentOrgId(orgId);
        setOrgChatModel(chatModel);
        setCurrentTab(TabType.TEAM);
    };

    const updateOrgDetails = (limitId: string, suspendUntil: string | null) => {
        setAllTeamDetails(allTeamDetails.map(limit => (limit.id === limitId ? { ...limit, suspended_until: suspendUntil } : limit)));
    };

    const { tokens } = useDesignSystem();

    if (!isTeamAdmin && !isTenantAdmin) {
        return <Loading />;
    }

    return (
        <div className={styles.container}>
            <AppBar admin={true} />
            <div
                style={{
                    paddingTop: appBarCkHeight
                }}
            >
                {isTenantAdmin && (
                    <div
                        style={{
                            borderBottom: `1px solid ${tokens.ThemeBorderMid}`,
                            backgroundColor: `${tokens.ThemeBodyBackground}`
                        }}
                        className={styles.tabs}
                    >
                        <Tabs value={currentTab} onChange={onTabChange}>
                            {<Tab label={trans.get("team_admin")} value={TabType.TEAM} sx={{ textTransform: "none" }} />}
                            {<Tab label={trans.get("tenant_overview")} value={TabType.TENANT} sx={{ textTransform: "none" }} />}
                        </Tabs>
                    </div>
                )}
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {currentTab == TabType.TEAM && teamDetails && <TeamAdmin teamDetails={teamDetails} onOrgUpdate={updateOrgDetails} />}
                        {currentTab == TabType.TENANT && tenantDetails && (
                            <TenantAdmin
                                tenant={tenantDetails.tenant}
                                orgLimitDetails={allTeamDetails}
                                setOrgLimitDetails={setAllTeamDetails}
                                setOrganization={viewOrgDetails}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Admin;

import { useState, useEffect } from "react";

import styles from "./Login.module.css";

import { loginApi } from "../../api";

import aiIcon from "../../assets/colorkrew_ai_chat_icon.svg";
import colorkrewLogoSvg from "../../assets/colorkrew_logo_svg.svg";
import Button from "@mui/material/Button";
import { trans } from "../../util/trans";
import { globalNavigate } from "../../components/GlobalNavigate/GlobalNavigate";
import { useSearchParams } from "react-router-dom";

const Login = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [authorizationUrl, setAuthorizationUrl] = useState<string>("");
    const [searchParams] = useSearchParams();
    useEffect(() => {
        loginApi(searchParams.get("tenantId"), searchParams.get("organizationId"))
            .then(res => {
                if (res.is_logged_in) {
                    globalNavigate("/chat");
                } else {
                    setAuthorizationUrl(res.authorization_url || "");
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <div>
            <div className={styles.container}>
                <img className={styles.aiIcon} src={aiIcon} alt="Stars svg" />
                <h1>{trans.get("empty_chat_prompt")}</h1>
                <div>
                    <b>{trans.get("powered_by_azure")}</b>
                </div>
                <div className={styles.instruction}>{trans.get("login_prompt")}</div>
                {isLoading ? (
                    <div>
                        {trans.get("loading")}
                        <span className={styles.loadingdots} />
                    </div>
                ) : (
                    <Button variant="contained" href={authorizationUrl} color="primary" startIcon={<img src={colorkrewLogoSvg} alt="Colorkrew Logo svg" />}>
                        {trans.get("colorkrew_id_login")}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Login;

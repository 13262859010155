import { useRef, useState, useEffect, ReactElement, useContext } from "react";

import styles from "./Chat.module.css";

import { chatApi, Approaches, ChatRequest, ChatResponseData, MessageResponseData, ChatMessageResponse } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import aiIcon from "../../assets/colorkrew_ai_chat_icon.svg";
import { trans } from "../../util/trans";
import { globalNavigate } from "../../components/GlobalNavigate/GlobalNavigate";
import { UserContext } from "../../contexts/UserContext";
import { isDateInFuture } from "../../util/helpers";
import ErrorAlert from "../../components/Error/ErrorAlert";
import { Alert, Typography, useDesignSystem } from "@colorkrew/design-system";

export const enum MessageRole {
    ASSISTANT = "assistant",
    USER = "user"
}

type Props = {
    chat: ChatResponseData | undefined;
    setChat: (chat: ChatResponseData) => void;
    updateChats: () => void;
};

const Chat = ({ chat, setChat, updateChats }: Props): ReactElement => {
    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [currentChatId, setCurrentChatId] = useState<string>("");
    const [messages, setMessages] = useState<MessageResponseData[]>([]);
    const [isChatUpdated, setIsChatUpdated] = useState<boolean>(false);

    const userCtx = useContext(UserContext);
    const isSuspended = isDateInFuture(userCtx?.user.suspended_until);
    const { tokens } = useDesignSystem();

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);

        try {
            const request: ChatRequest = {
                question,
                approach: Approaches.ReadRetrieveRead,
                overrides: {},
                chatId: chat?.id,
                parent: messages.length > 0 ? messages[messages.length - 1].id : undefined
            };
            const result: ChatMessageResponse = await chatApi(request);
            if (result.data) {
                const updatedChat: ChatResponseData = result.data.chat;
                // Redirect on new chat
                if (updatedChat.id != currentChatId) {
                    globalNavigate(`/chat/${updatedChat.id}`);
                }
                // Update chat list on the first edit of a chat
                if (!isChatUpdated) {
                    updateChats();
                    setIsChatUpdated(true);
                }
                setChat(updatedChat);
                setCurrentChatId(updatedChat.id);
                setMessages([...messages, updatedChat.history[result.data.question_id], updatedChat.history[result.data.answer_id]]);
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const clearChat = () => {
        setIsChatUpdated(false);
        lastQuestionRef.current = "";
        error && setError(undefined);
        setMessages([]);
        setCurrentChatId("");
    };

    const isEmptyState = () => {
        return !(error || isLoading || messages?.length > 0 || chat);
    };

    const constructMessages = (chatData: ChatResponseData) => {
        const history = chatData.history;
        const tempMessages = [];
        let currNode = history[chatData.current_node];
        while (currNode) {
            tempMessages.unshift(currNode);
            currNode = history[currNode.parent];
        }
        setMessages(tempMessages);
    };

    useEffect(() => {
        if (chat) {
            if (chat.id !== currentChatId) {
                setIsChatUpdated(false);
                setCurrentChatId(chat.id);
                constructMessages(chat);
            }
        } else {
            clearChat();
        }
    }, [chat]);

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [messages, isLoading]);

    return (
        <div className={styles.container}>
            {isEmptyState() && isSuspended ? (
                <div className={styles.suspendedError}>
                    <ErrorAlert header={trans.get("service_paused_by_admin")} description={trans.get("service_suspension_alert")} />
                </div>
            ) : (
                <div className={styles.chatRoot}>
                    <div className={styles.chatHistoryContainer}>
                        {isEmptyState() && (
                            <div className={styles.logoContainer}>
                                <img className={styles.aiIcon} src={aiIcon} alt="Stars svg" />
                                <h1>{trans.get("empty_chat_prompt")}</h1>
                            </div>
                        )}
                        <div className={styles.chatHistory}>
                            <div className={styles.chatMessageStreamContainer}>
                                {isSuspended && (
                                    <div style={{ backgroundColor: tokens.ThemeBodyBackground }} className={styles.errorAlert}>
                                        <Alert severity="error" color="error">
                                            <div className={styles.errorAlertText}>
                                                <Typography variant="headingXS">{trans.get("service_paused_by_admin")}</Typography>
                                                <Typography variant="bodyS">{trans.get("service_suspension_alert")}</Typography>
                                            </div>
                                        </Alert>
                                    </div>
                                )}
                                <div className={styles.chatMessageStream}>
                                    {messages.map((message, index) => (
                                        <div key={index}>
                                            {message.role === MessageRole.USER && <UserChatMessage message={message.content} />}
                                            {message.role === MessageRole.ASSISTANT && (
                                                <div className={styles.chatMessageGpt}>
                                                    <Answer key={index} answer={message.content} />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    {isLoading && (
                                        <>
                                            <UserChatMessage message={lastQuestionRef.current} />
                                            <div className={styles.chatMessageGptMinWidth}>
                                                <AnswerLoading />
                                            </div>
                                        </>
                                    )}
                                    {error ? (
                                        <>
                                            <UserChatMessage message={lastQuestionRef.current} />
                                            <div className={styles.chatMessageGptMinWidth}>
                                                <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                                <div ref={chatMessageStreamEnd} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.chatInputContainer}>
                        <QuestionInput
                            clearOnSend
                            placeholder={trans.get("chat_input_placeholder")}
                            disabled={isLoading || isSuspended}
                            onSend={question => makeApiRequest(question)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chat;

import { Stack } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { parseAnswerToHtml } from "./AnswerParser";

interface Props {
    answer: string;
}

function wrapCodeSnippets(answer: string): string {
    const regex = /```[0-9a-zA-Z]*\n([\s\S]*?)```/g;
    const replacement = "<pre>$1</pre>";
    const output = answer.replace(regex, replacement);
    return output;
}

export const Answer = ({ answer }: Props) => {
    const parsedAnswer = parseAnswerToHtml(answer);

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    return (
        <Stack className={styles.answerContainer} verticalAlign="space-between">
            <Stack.Item grow>
                <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: wrapCodeSnippets(sanitizedAnswerHtml) }}></div>
            </Stack.Item>
        </Stack>
    );
};

import styles from "./Home.module.css";

import { ReactElement, useEffect, useState } from "react";
import { ChatResponse, ChatResponseData, SummaryResponseData, getAllChats, getAllSummaries, getChat, getSummary } from "../../api";
import HistorySideMenu from "../chat/HistorySideMenu";
import Chat from "../chat/Chat";
import Summary from "../summary/Summary";
import { useParams } from "react-router-dom";
import { appBarCkHeight } from "@colorkrew/design-system/dist/components/AppBarCk/AppBarCk";
import { globalNavigate } from "../../components/GlobalNavigate/GlobalNavigate";
import AppBar from "../../components/AppBar/AppBar";

export const enum DisplayType {
    CHAT,
    SUMMARY
}

export type SummaryMap = {
    [key: string]: SummaryResponseData;
};

type Props = {
    initialDisplay: DisplayType;
};

const Home = ({ initialDisplay }: Props): ReactElement => {
    const { summaryId, chatId } = useParams();

    const [displayMode, setDisplayMode] = useState<DisplayType>(DisplayType.CHAT);
    const [chats, setChats] = useState<ChatResponseData[]>([]);
    const [summaries, setSummaries] = useState<SummaryResponseData[]>([]);
    const [summaryMap, setSummaryMap] = useState<SummaryMap>({});
    const [currentChat, setCurrentChat] = useState<ChatResponseData | undefined>();
    const [currentSummary, setCurrentSummary] = useState<SummaryResponseData | undefined>();
    const [historyLoading, setHistoryLoading] = useState<boolean>(true);
    const [activeItemId, setActiveItemId] = useState<string | undefined>(undefined);

    // Load history on initial page load
    useEffect(() => {
        initialLoad();
    }, []);

    useEffect(() => {
        setDisplayMode(initialDisplay);
    }, [initialDisplay]);

    const initialLoad = async () => {
        setHistoryLoading(true);

        if (chatId) {
            setActiveItemId(chatId);
            try {
                const currChat: ChatResponse = await getChat(chatId);
                setCurrentChat(currChat.chat);
            } catch (e) {
                globalNavigate("/chat");
            }
        }

        if (summaryId) {
            setActiveItemId(summaryId);
            let currSummary: SummaryResponseData | undefined;
            if (summaryId in summaryMap) {
                // check if it ever gets into this function (is summarymap ever initialized on initialLoad?)
                currSummary = summaryMap[summaryId];
            } else {
                try {
                    const response = await getSummary(summaryId);
                    currSummary = response.summary;
                    setCurrentSummary(currSummary);
                } catch (e) {
                    globalNavigate("/summary");
                }
            }
        }
        await updateChats();
        await updateSummaries();
        setHistoryLoading(false);
    };

    const updateChats = async () => {
        setHistoryLoading(true);
        const response = await getAllChats();
        setChats(response?.chats ?? []);
        setHistoryLoading(false);
    };

    const updateSummaries = async () => {
        setHistoryLoading(true);
        const response = await getAllSummaries();
        const tempSummaries = response?.summaries ?? [];
        const tempSummaryMap: SummaryMap = tempSummaries.reduce((acc: SummaryMap, summary: SummaryResponseData) => {
            acc[summary.id] = summary;
            return acc;
        }, {});
        setSummaries(tempSummaries);
        setSummaryMap(tempSummaryMap);
        setHistoryLoading(false);
    };

    const selectChat = async (id: string) => {
        try {
            const response = await getChat(id);
            setCurrentChat(response.chat);
            setActiveItemId(id);
        } catch (e) {
            globalNavigate("/chat");
        }
    };

    const selectSummary = (id: string) => {
        if (id in summaryMap) {
            const summary = summaryMap[id];
            setCurrentSummary(summary);
            setActiveItemId(id);
        } else {
            globalNavigate("/summary");
        }
    };

    const newChat = () => {
        setCurrentChat(undefined);
        setActiveItemId(undefined);
        globalNavigate("/chat");
    };

    const newSummary = () => {
        setCurrentSummary(undefined);
        setActiveItemId(undefined);
        globalNavigate("/summary");
    };

    return (
        <>
            <AppBar admin={false} />
            <div style={{ paddingTop: appBarCkHeight }} className={styles.container}>
                <HistorySideMenu
                    loading={historyLoading}
                    currentItemId={activeItemId}
                    chats={chats}
                    summaries={summaries}
                    newChat={newChat}
                    newSummary={newSummary}
                    onChatClick={selectChat}
                    onSummaryClick={selectSummary}
                    updateChats={updateChats}
                    updateSummaries={updateSummaries}
                />
                {displayMode === DisplayType.CHAT && (
                    <Chat
                        chat={currentChat}
                        setChat={(c: ChatResponseData) => {
                            setCurrentChat(c);
                            setActiveItemId(c.id);
                        }}
                        updateChats={updateChats}
                    />
                )}
                {displayMode === DisplayType.SUMMARY && (
                    <Summary
                        summary={currentSummary}
                        setSummary={(s: SummaryResponseData) => {
                            setCurrentSummary(s);
                            setActiveItemId(s.id);
                        }}
                        updateSummaries={updateSummaries}
                    />
                )}
            </div>
        </>
    );
};

export default Home;

import { useDesignSystem } from "@colorkrew/design-system";
// This is a temporal page to host Terms and Use page.
// Terms and Use page would be hosted in CKID in the future.
// This is a public page where we don't require users to be authenticated
import { ReactElement, useRef, useState } from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import styles from "./Terms.module.css";
import colorkrewLogo from "../../assets/colorkrew_ai_chat.svg";

const Terms = (): ReactElement => {
    const [content, setContent] = useState<string>("");
    const isLoaded = useRef<boolean>(false);
    const { tokens } = useDesignSystem();

    if (!isLoaded.current) {
        fetch("/terms.md")
            .then(res => res.text())
            .then(text => {
                setContent(text);
                isLoaded.current = true;
            });
    }

    return (
        <>
            <div className={styles.appBar} style={{ borderBottom: `1px solid ${tokens.ThemeBorderMid}` }}>
                <img src={colorkrewLogo} />
            </div>
            <div className={styles.markdownContainer}>
                <Markdown
                    remarkPlugins={[remarkGfm]} // this plugin is to render tables
                    rehypePlugins={[rehypeRaw]} // this plugin is not to escape html elements in markdown
                    components={{
                        table: ({ children }) => (
                            <table border={1} cellPadding="8" style={{ borderCollapse: "collapse" }}>
                                {children}
                            </table>
                        )
                    }}
                >
                    {content}
                </Markdown>
            </div>
        </>
    );
};

export default Terms;

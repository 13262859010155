import { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./AppBar.module.css";
import { AppBarCk, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@colorkrew/design-system";
import colorkrewLogo from "../../assets/colorkrew_ai_chat.svg";
import { AdminPanelSettings } from "@colorkrew/design-system/dist/icons";
import { getProfileUser } from "../../util/helpers";
import { UserContext } from "../../contexts/UserContext";
import { globalNavigate } from "../GlobalNavigate/GlobalNavigate";
import { trans } from "../../util/trans";
import { AccessRole, teamSwitchApi } from "../../api";

type Props = {
    admin: boolean;
};

const AppBar = (props: Props): ReactElement => {
    const userCtx = useContext(UserContext);
    const [currentTeamId, setCurrentTeamId] = useState<string>(userCtx?.user.organization_id ?? "");
    const [tenantId, setTenantId] = useState<string>(userCtx?.user.tenant_id ?? "");
    const [isTeamAdmin, setIsTeamAdmin] = useState<boolean>(false);
    const [isTenantAdmin, setIsTenantAdmin] = useState<boolean>(false);

    useEffect(() => {
        const user = userCtx?.user;
        if (user) {
            setIsTeamAdmin(user.organization_role == AccessRole.Admin);
            setIsTenantAdmin(user.tenant_role == AccessRole.Admin);
            if (user.organization_id) {
                setCurrentTeamId(user.organization_id);
            }
            if (user.tenant_id) {
                setTenantId(user.tenant_id);
            }
        }
    }, [userCtx]);

    const toggleAdmin = () => {
        if (props.admin) {
            globalNavigate("/chat");
        } else {
            globalNavigate("/admin");
        }
    };

    const teamSwitch = (orgId: string) => {
        setCurrentTeamId(orgId);
        teamSwitchApi({ organization_id: orgId, tenant_id: tenantId }).then(res => {
            if (res.authorization_url) {
                window.location.href = res.authorization_url;
            }
        });
    };

    return (
        <AppBarCk user={getProfileUser(userCtx?.user, userCtx?.tenant?.name)} logoAlt="Colorkrew OpenAI" logoUrl={colorkrewLogo}>
            <div className={styles.appBarActions}>
                <FormControl size="small">
                    <InputLabel id="teamLabel">{trans.get("team")}</InputLabel>
                    <Select labelId="teamLabel" label={trans.get("team")} value={currentTeamId} onChange={e => teamSwitch(e.target.value as string)}>
                        {userCtx?.organizations.map(org => (
                            <MenuItem key={org.id} value={org.id}>
                                {org.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {(isTeamAdmin || isTenantAdmin) && (
                    <Button uppercase={false} onClick={toggleAdmin} variant={props.admin ? "contained" : "outlined"}>
                        <div className={styles.buttonContents}>
                            <AdminPanelSettings />
                            <Typography>{props.admin ? trans.get("back_to_dashboard") : trans.get("admin")}</Typography>
                        </div>
                    </Button>
                )}
            </div>
        </AppBarCk>
    );
};

export default AppBar;

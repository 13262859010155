import { globalNavigate } from "../components/GlobalNavigate/GlobalNavigate";
import {
    LoginResponse,
    ChatRequest,
    SummaryRequest,
    SummaryResponse,
    SummaryFileRequest,
    AllSummaryResponse,
    BaseResponse,
    AllChatResponse,
    DeleteResponse,
    UpdateTitleRequest,
    ChatMessageResponse,
    ChatResponse,
    UserDetailsResponse,
    LogoutResponse,
    TeamSwitchRequest,
    TeamAdminResponse,
    TenantAdminResponse,
    ToggleServiceRequest,
    ToggleServiceResponse,
    ChatModel,
    TaskResponse,
    TaskStatusResponse
} from "./models";

const BASE_REQUEST_OPTIONS: RequestInit = {
    headers: {
        "Content-Type": "application/json"
    },
    credentials: "include"
};

async function makeRequest(url: string, customOptions?: RequestInit): Promise<Response> {
    const requestOptions: RequestInit = {
        ...BASE_REQUEST_OPTIONS,
        ...customOptions
    };
    return fetch(url, requestOptions);
}

async function handleResponse(response: Response) {
    if (response.status === 401) {
        globalNavigate("/login");
        return;
    }

    let errorMessage;
    try {
        const parsedResponse: BaseResponse = await response.json();
        if (response.status > 299 || !response.ok) {
            errorMessage = parsedResponse.error;
        } else {
            return parsedResponse;
        }
    } catch (e) {
        throw Error("Something went wrong. Please try again");
    }

    if (errorMessage) {
        throw Error(errorMessage);
    }
}

export async function getTaskStatus(task_id: string): Promise<TaskStatusResponse> {
    const response = await makeRequest(`/api/v1/task/${task_id}`, {
        method: "GET"
    });
    return (await handleResponse(response)) as TaskStatusResponse;
}

export async function getChat(id: string): Promise<ChatResponse> {
    const response = await makeRequest(`/api/v1/chat/${id}`, {
        method: "GET"
    });
    return (await handleResponse(response)) as ChatResponse;
}

export async function getAllChats(): Promise<AllChatResponse> {
    const response = await makeRequest("/api/v1/chat", {
        method: "GET"
    });
    return (await handleResponse(response)) as AllChatResponse;
}

export async function chatApi(options: ChatRequest): Promise<ChatMessageResponse> {
    const response = await makeRequest("/api/v1/chat", {
        method: "POST",
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            },
            chat_id: options.chatId,
            parent: options.parent
        })
    });

    return (await handleResponse(response)) as ChatMessageResponse;
}

export async function updateChat(request: UpdateTitleRequest): Promise<ChatResponse> {
    const response = await makeRequest(`/api/v1/chat/${request.id}`, {
        method: "POST",
        body: JSON.stringify(request)
    });
    return (await handleResponse(response)) as ChatResponse;
}

export async function deleteChat(id: string): Promise<DeleteResponse> {
    const response = await makeRequest(`/api/v1/chat/${id}`, {
        method: "DELETE"
    });
    return (await handleResponse(response)) as DeleteResponse;
}

export async function getSummary(id: string): Promise<SummaryResponse> {
    const response = await makeRequest(`/api/v1/summary/${id}`, {
        method: "GET"
    });
    return (await handleResponse(response)) as SummaryResponse;
}

export async function getAllSummaries(): Promise<AllSummaryResponse> {
    const response = await makeRequest("/api/v1/summary", {
        method: "GET"
    });
    return (await handleResponse(response)) as AllSummaryResponse;
}

export async function updateSummary(request: UpdateTitleRequest): Promise<SummaryResponse> {
    const response = await makeRequest(`/api/v1/summary/${request.id}`, {
        method: "POST",
        body: JSON.stringify(request)
    });
    return (await handleResponse(response)) as SummaryResponse;
}

export async function summarize(request: SummaryRequest): Promise<TaskResponse> {
    const response = await makeRequest("/api/v1/summary", {
        method: "POST",
        body: JSON.stringify(request)
    });

    return (await handleResponse(response)) as TaskResponse;
}

export async function summarizeFile(request: SummaryFileRequest): Promise<TaskResponse> {
    const data = new FormData();
    data.append("file", request.file);
    data.append("fileSize", String(request.fileSize));
    data.append("customPrompt", request.customPrompt ?? "");
    data.append("depth", String(request.depth));

    const response = await fetch("/api/v1/summary/file", {
        method: "POST",
        body: data,
        credentials: "include"
    });
    return (await handleResponse(response)) as TaskResponse;
}

export async function deleteSummary(id: string): Promise<DeleteResponse> {
    const response = await makeRequest(`/api/v1/summary/${id}`, {
        method: "DELETE"
    });
    return (await handleResponse(response)) as DeleteResponse;
}

export async function loginApi(tenantId: string | null, orgId: string | null): Promise<LoginResponse> {
    const url = orgId && tenantId ? `/api/v1/login?tenant_id=${tenantId}&organization_id=${orgId}` : "/api/v1/login";
    const response = await makeRequest(url, {
        method: "GET"
    });
    return (await handleResponse(response)) as LoginResponse;
}

export async function logoutApi(): Promise<LogoutResponse> {
    const response = await makeRequest("/api/v1/logout", {
        method: "GET"
    });
    return (await handleResponse(response)) as LogoutResponse;
}

export async function teamSwitchApi(request: TeamSwitchRequest): Promise<LoginResponse> {
    const response = await makeRequest("/api/v1/team", {
        method: "POST",
        body: JSON.stringify(request)
    });
    return (await handleResponse(response)) as LoginResponse;
}

export async function teamAdminApi(orgId: string, chatModel?: ChatModel): Promise<TeamAdminResponse> {
    let url = `/api/v1/admin/team/${orgId}`;
    if (chatModel) {
        url +=
            "?" +
            new URLSearchParams({
                chat_model: chatModel
            });
    }
    const response = await makeRequest(url, {
        method: "GET"
    });
    return (await handleResponse(response)) as TeamAdminResponse;
}

export async function tenantAdminApi(): Promise<TenantAdminResponse> {
    const response = await makeRequest(`/api/v1/admin/tenant`, {
        method: "GET"
    });
    return (await handleResponse(response)) as TenantAdminResponse;
}

export async function suspendService(request: ToggleServiceRequest): Promise<ToggleServiceResponse> {
    const response = await makeRequest("/api/v1/admin/suspend", {
        method: "POST",
        body: JSON.stringify(request)
    });
    return (await handleResponse(response)) as ToggleServiceResponse;
}

export async function restoreService(request: ToggleServiceRequest): Promise<ToggleServiceResponse> {
    const response = await makeRequest("/api/v1/admin/restore", {
        method: "POST",
        body: JSON.stringify(request)
    });
    return (await handleResponse(response)) as ToggleServiceResponse;
}

export async function expandTokens(limit_ids: string[]): Promise<BaseResponse> {
    const response = await makeRequest("/api/v1/admin/team/expand", {
        method: "POST",
        body: JSON.stringify({ limit_ids: limit_ids })
    });
    return (await handleResponse(response)) as BaseResponse;
}

export async function upgradeGpt(limit_ids: string[]): Promise<BaseResponse> {
    const response = await makeRequest("/api/v1/admin/team/upgrade", {
        method: "POST",
        body: JSON.stringify({ limit_ids: limit_ids })
    });
    return (await handleResponse(response)) as BaseResponse;
}

export async function downgradeGpt(limit_ids: string[]): Promise<BaseResponse> {
    const response = await makeRequest("/api/v1/admin/team/downgrade", {
        method: "POST",
        body: JSON.stringify({ limit_ids: limit_ids })
    });
    return (await handleResponse(response)) as BaseResponse;
}

export function getCitationFilePath(citation: string): string {
    return `/content/${citation}`;
}

export async function getUserDetails(): Promise<UserDetailsResponse> {
    const response = await makeRequest(`/api/v1/user`, {
        method: "GET"
    });
    return (await handleResponse(response)) as UserDetailsResponse;
}

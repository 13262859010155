import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import styles from "./Summary.module.css";
import { IconButton, useDesignSystem } from "@colorkrew/design-system";
import { ContentCopy } from "@colorkrew/design-system/dist/icons";
import { trans } from "../../util/trans";
import { SummaryFileRequest, SummaryRequest, SummaryResponseData, getTaskStatus, summarize, summarizeFile } from "../../api";
import SummaryEditor from "./SummaryEditor";
import SelectedFile from "../../components/FileUpload/SelectedFile";
import { Answer, AnswerError } from "../../components/Answer";
import { CircularProgress } from "@mui/material";
import TextModal from "../../components/Modal/TextModal";
import { globalNavigate } from "../../components/GlobalNavigate/GlobalNavigate";
import { UserContext } from "../../contexts/UserContext";
import ErrorAlert from "../../components/Error/ErrorAlert";
import { isDateInFuture } from "../../util/helpers";

const TRUNCATE_LENGTH = 300;

type Props = {
    summary: SummaryResponseData | undefined;
    setSummary: (summary: SummaryResponseData) => void;
    updateSummaries: () => void;
};

const Summary = ({ summary, setSummary, updateSummaries }: Props): ReactElement => {
    const intervalRef = useRef<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [viewSource, setViewSource] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const userCtx = useContext(UserContext);
    const { tokens } = useDesignSystem();

    useEffect(() => {
        // Clean up interval on component unmount or when polling stops
        return () => stopPolling();
    }, []);

    const startPolling = (taskId: string) => {
        setLoading(true);
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        const intervalId = setInterval(async () => {
            const isCompleted = await checkStatus(taskId);
            if (isCompleted) {
                setLoading(false);
                clearInterval(intervalId);
            }
        }, 2000);
        intervalRef.current = intervalId;
    };

    const stopPolling = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    const checkStatus = async (taskId: string) => {
        try {
            const response = await getTaskStatus(taskId);
            const summary = response.summary;
            if (summary) {
                updateSummaries();
                setSummary(summary);
                setLoading(false);
                stopPolling();
                globalNavigate(`/summary/${summary.id}`);
                return true;
            }
            if (response.error) {
                handleError();
            }
        } catch (e) {
            handleError();
        }
        return false;
    };

    const handleError = () => {
        setError(trans.get("error_summarize_fail"));
        setLoading(false);
        stopPolling();
    };

    const summarizeSubmit = async (question: string, customPrompt: string, depth: number) => {
        setLoading(true);
        setError(undefined);
        try {
            const request: SummaryRequest = {
                question,
                customPrompt,
                depth
            };
            const response = await summarize(request);
            if (response.task_id) {
                startPolling(response.task_id);
            } else {
                handleError();
            }
        } catch (e) {
            setError(e);
        }
    };

    const summarizeFileUpload = async (file: File, customPrompt: string, depth: number) => {
        setLoading(true);
        setError(undefined);
        try {
            const request: SummaryFileRequest = {
                file,
                fileSize: file.size,
                customPrompt,
                depth
            };
            const response = await summarizeFile(request);
            if (response.task_id) {
                startPolling(response.task_id);
            } else {
                handleError();
            }
        } catch (e) {
            setError(e);
        }
    };

    return (
        <div className={styles.container}>
            {summary ? (
                <div className={styles.content}>
                    {viewSource && (
                        <TextModal header={trans.get("source")} handleClose={() => setViewSource(false)}>
                            {summary.question}
                        </TextModal>
                    )}
                    <div>
                        <h3>{trans.get("summarize")}</h3>
                        {summary.file_name ? (
                            <SelectedFile fileName={summary.file_name} fileSize={parseInt(summary.file_size)} />
                        ) : (
                            <div className={styles.userText}>
                                {summary.question.length > TRUNCATE_LENGTH ? (
                                    <>
                                        <div>{summary.question.slice(0, TRUNCATE_LENGTH) + "..."} </div>
                                    </>
                                ) : (
                                    <div>{summary.question}</div>
                                )}
                                <div className={styles.viewMore} style={{ color: tokens.ThemeSurfaceAccentPrimary }} onClick={() => setViewSource(true)}>
                                    {trans.get("view_complete_source")}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.answerContainer}>
                        <Answer answer={summary.answer} />
                        <IconButton
                            color="primary"
                            onClick={() => {
                                navigator.clipboard.writeText(summary.answer);
                            }}
                        >
                            <ContentCopy fontSize="small" />
                        </IconButton>
                    </div>
                </div>
            ) : isDateInFuture(userCtx?.user.suspended_until) ? (
                <div className={styles.content}>
                    <ErrorAlert header={trans.get("service_paused_by_admin")} description={trans.get("service_suspension_alert")} />
                </div>
            ) : (
                <SummaryEditor loading={loading} handleTextSubmit={summarizeSubmit} handleFileSubmit={summarizeFileUpload} />
            )}
            {loading && (
                <div className={styles.loadingSpinner}>
                    <CircularProgress />
                </div>
            )}
            {error ? (
                <div className={styles.errorMessage}>
                    <AnswerError error={error.toString()} />
                </div>
            ) : null}
        </div>
    );
};

export default Summary;

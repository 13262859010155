import { PropsWithChildren, ReactElement } from "react";
import styles from "./ConfirmationModal.module.css";
import { Button, useDesignSystem } from "@colorkrew/design-system";
import { trans } from "../../util/trans";

type Props = {
    handleClose?: () => void;
    handleSubmit?: () => void;
};

const ConfirmationModal = ({ children, handleClose, handleSubmit }: PropsWithChildren<Props>): ReactElement => {
    const { tokens } = useDesignSystem();

    return (
        <div style={{ backgroundColor: tokens.ThemeSurfaceOverlay }} className={styles.container} onClick={handleClose}>
            <div
                style={{ backgroundColor: tokens.ThemeSurfacePrimary }}
                className={styles.content}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                {children}
                {handleClose && handleSubmit && (
                    <div className={styles.actionButtonContainer}>
                        <Button uppercase={false} color="inherit" variant="contained" onClick={handleClose}>
                            {trans.get("cancel")}
                        </Button>
                        <Button
                            uppercase={false}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                handleSubmit();
                                handleClose();
                            }}
                        >
                            {trans.get("confirm")}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ConfirmationModal;

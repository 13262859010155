import { ReactElement, useMemo, useState } from "react";
import styles from "./SummaryEditor.module.css";
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField, useDesignSystem } from "@colorkrew/design-system";
import SummarySettings from "./SummarySettings";
import { trans } from "../../util/trans";
import FileUpload from "../../components/FileUpload/FileUpload";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";

export const enum InputType {
    TEXT,
    FILE
}

type Props = {
    loading: boolean;
    handleTextSubmit: (question: string, customPrompt: string, depth: number) => void;
    handleFileSubmit: (file: File, customPrompt: string, depth: number) => void;
};

const SummaryEditor = ({ loading, handleTextSubmit, handleFileSubmit }: Props): ReactElement => {
    const [question, setQuestion] = useState<string>("");
    const [selectedFile, setSelectedFile] = useState<File | undefined>();
    const [error, setError] = useState<string>("");
    const [depth, setDepth] = useState<number>(50);
    const [customPrompt, setCustomPrompt] = useState<string>("");
    const [inputType, setInputType] = useState<InputType>(InputType.TEXT);
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const textLength = useMemo(() => question.replace(/\n/g, "").length.toString(), [question]);

    const { tokens } = useDesignSystem();

    const isFormInvalid = (): boolean => {
        return (inputType === InputType.TEXT && question.trim().length === 0) || (inputType === InputType.FILE && selectedFile === undefined);
    };

    const handleSubmit = () => {
        switch (inputType) {
            case InputType.TEXT:
                handleTextSubmit(question, customPrompt, depth);
                break;
            case InputType.FILE:
                if (selectedFile) {
                    handleFileSubmit(selectedFile, customPrompt, depth);
                } else {
                    setError(trans.get("error_upload_file"));
                }
                break;
        }
    };
    return (
        <div className={styles.container}>
            {showSettings && (
                <ConfirmationModal>
                    <SummarySettings
                        currentDepth={depth}
                        currentPrompt={customPrompt}
                        handleCancel={() => setShowSettings(false)}
                        handleSave={(depth: number, prompt: string) => {
                            setShowSettings(false);
                            setDepth(depth);
                            setCustomPrompt(prompt);
                        }}
                    />
                </ConfirmationModal>
            )}
            <div className={styles.header}>
                <h3>{trans.get("summarize")}</h3>
                <FormControl>
                    <RadioGroup
                        value={inputType}
                        name="radio-buttons-group"
                        onChange={e => {
                            switch (e.target.value) {
                                case InputType.TEXT.toString():
                                    setInputType(InputType.TEXT);
                                    break;
                                case InputType.FILE.toString():
                                    setInputType(InputType.FILE);
                                    break;
                            }
                        }}
                        row
                    >
                        <FormControlLabel control={<Radio />} label={trans.get("summary_pasted_text")} value={InputType.TEXT} />
                        <FormControlLabel control={<Radio />} label={trans.get("summary_file")} value={InputType.FILE} />
                    </RadioGroup>
                </FormControl>
            </div>
            {inputType === InputType.TEXT && (
                <>
                    <Box sx={{ backgroundColor: tokens.ThemeSurfacePrimary }}>
                        <TextField
                            multiline
                            fullWidth
                            rows={6}
                            placeholder={trans.get("summary_text_placeholder")}
                            size="medium"
                            type="text"
                            value={question}
                            onChange={e => {
                                setQuestion(e.target.value);
                            }}
                        />
                    </Box>
                    <div style={{ color: tokens.ThemeForegroundLow }} className={styles.countCharacters}>
                        {trans.get("character_count", { num: textLength })}
                    </div>
                </>
            )}
            {inputType === InputType.FILE && (
                <FileUpload disableRemove={loading} selectedFile={selectedFile} handleFileSelect={setSelectedFile} handleError={setError} />
            )}
            <div style={{ color: tokens.ThemeSurfaceFeedbackError }}>{error}</div>
            <div className={styles.actionButtonContainer}>
                <Button uppercase={false} color="inherit" onClick={() => setShowSettings(true)}>
                    {trans.get("settings")}
                </Button>
                <Button uppercase={false} color="primary" variant="contained" onClick={handleSubmit} disabled={isFormInvalid() || loading}>
                    {trans.get("submit")}
                </Button>
            </div>
        </div>
    );
};

export default SummaryEditor;

import { ReactElement, useState } from "react";
import styles from "./SuspendServiceSettingsModal.module.css";
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@colorkrew/design-system";
import { trans } from "../../util/trans";
import { EntityType } from "../../pages/admin/TeamAdmin";
import { getDateTimeFromSuspendDateType, getUserDisplayName } from "../../util/helpers";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { TokenLimit, suspendService } from "../../api";

export const enum SuspendDateType {
    TOMORROW = "tomorrow",
    NEXT_WEEK = "nextWeek",
    NEXT_MONTH = "nextMonth",
    END_OF_TIME = "endOfTime"
}

type Props = {
    limitIds: string[];
    type: EntityType;
    userName?: string;
    setError: (error: string) => void;
    onClose: () => void;
    onConfirm: (limitIds: string[], suspendUntil: string) => void;
};

const SuspendServiceSettingsModal = ({ limitIds, type, userName, setError, onClose: handleClose, onConfirm: handleConfirm }: Props): ReactElement => {
    const [dateType, setDateType] = useState<SuspendDateType>(SuspendDateType.TOMORROW);

    const prompt = type === EntityType.TEAM ? trans.get("confirmation_suspend_team") : trans.get("confirmation_suspend_user", { name: userName ?? "" });

    const confirmStopService = () => {
        if (limitIds) {
            const suspendUntil = getDateTimeFromSuspendDateType(dateType);
            suspendService({ limit_ids: limitIds, suspend_until: suspendUntil }).then(resp => {
                handleConfirm(limitIds, suspendUntil);
            });
        } else {
            setError(trans.get("suspend_failure"));
        }
    };

    return (
        <ConfirmationModal handleClose={handleClose} handleSubmit={confirmStopService}>
            <div className={styles.container}>
                <Typography variant="headingS">{trans.get("stop_service")}</Typography>
                <Typography variant="bodyM">{prompt}</Typography>
                <div className={styles.dateSelection}>
                    <FormControl>
                        <Typography variant="bodyM">{trans.get("service_suspend_prompt")}</Typography>
                        <RadioGroup
                            value={dateType}
                            name="radio-buttons-group"
                            onChange={e => {
                                const selectedDateType = e.target.value as SuspendDateType;
                                setDateType(selectedDateType);
                            }}
                        >
                            <FormControlLabel control={<Radio />} label={trans.get("one_day")} value={SuspendDateType.TOMORROW} />
                            <FormControlLabel control={<Radio />} label={trans.get("one_week")} value={SuspendDateType.NEXT_WEEK} />
                            <FormControlLabel control={<Radio />} label={trans.get("until_next_month")} value={SuspendDateType.NEXT_MONTH} />
                            <FormControlLabel control={<Radio />} label={trans.get("until_further_notice")} value={SuspendDateType.END_OF_TIME} />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </ConfirmationModal>
    );
};

export default SuspendServiceSettingsModal;

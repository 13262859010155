import styles from "./SelectedFile.module.css";

import { Button, useDesignSystem } from "@colorkrew/design-system";
import { InsertDriveFileOutlined } from "@colorkrew/design-system/dist/icons";
import { ReactElement } from "react";
import { getFileSizeInMb } from "../../util/helpers";
import { trans } from "../../util/trans";

type Props = {
    disableRemove?: boolean;
    fileName: string;
    fileSize: number;
    handleFileRemove?: () => void;
};

const SelectedFile = ({ disableRemove = false, fileName, fileSize, handleFileRemove }: Props): ReactElement => {
    const fileSizeText = isNaN(fileSize) ? "" : getFileSizeInMb(fileSize);

    const { tokens } = useDesignSystem();

    return (
        <div style={{ border: `1px solid ${tokens.ThemeBorderMid}`, backgroundColor: tokens.ThemeSurfacePrimary }} className={styles.uploadedFile}>
            <div className={styles.fileInfoContainer}>
                <InsertDriveFileOutlined />
                <div className={styles.fileInfo}>
                    <div className={styles.fileName}>{fileName}</div>
                    <div style={{ color: tokens.ThemeForegroundLow }}>{fileSizeText}</div>
                </div>
            </div>
            {handleFileRemove && (
                <Button disabled={disableRemove} uppercase={false} color="error" onClick={handleFileRemove}>
                    {trans.get("remove")}
                </Button>
            )}
        </div>
    );
};

export default SelectedFile;

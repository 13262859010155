import { Translation } from "./Translation";
import transEn from "./lang/en.json";
import transJa from "./lang/ja.json";
import transPt from "./lang/pt.json";
import transPtBr from "./lang/pt-BR.json";

export const enum LanguageEnum {
    EN = "en",
    JA = "ja",
    PT = "pt",
    PT_BR = "pt-BR"
}

export const trans = new Translation("en");

trans.loadData(LanguageEnum.EN, transEn as Record<string, string>);
trans.loadData(LanguageEnum.JA, transJa as Record<string, string>);
trans.loadData(LanguageEnum.PT, transPt as Record<string, string>);
trans.loadData(LanguageEnum.PT_BR, transPtBr as Record<string, string>);

import { SupportedLanguage } from "@colorkrew/design-system";

type Language = SupportedLanguage;

/* Copied from mamoru-biz-frontend-seat Translation.ts */
/* https://github.com/Colorkrew/biz-portal-frontend-seat/blob/test/frontend/src/seatxbi/util/Translation.ts */
export class Translation {
    /**
     * @param {string} defaultLanguage
     */
    constructor(defaultLanguage: Language) {
        this.lang = defaultLanguage;
        this.defLang = defaultLanguage;

        this.data = new Map();
        this.data.set(defaultLanguage, new Map());
    }

    private lang: Language;
    private readonly defLang: Language;
    private readonly data: Map<Language, Map<string, string>>;

    /**
     * Force set which language to use.
     * @param language - The language code (E.g. "en", "ja")
     */
    setLang(language: Language) {
        this.lang = language;
        if (!this.data.has(language)) {
            this.data.set(language, new Map());
        }
    }

    getLang(): Language {
        return this.lang;
    }

    // /**
    //  * Add a new key/value pair to the currently set language.
    //  * @param key
    //  * @param str
    //  */
    // add(key: string, str: string) {
    //     this.data.get(this.lang)?.set(key, str);
    // }

    /**
     * Get a text by key to display to the user.
     * It fails back to the default language, if it was not found in the current language.
     * If it was not found in either language, it will return an empty string and log an error in the console.
     * @param key translation key
     * @returns The text to display to the user.
     */
    get(key: string, tags: { [k: string]: string } = {}): string {
        const ld = this.data.get(this.lang);
        if (ld?.has(key)) {
            return this.interpolate(ld.get(key) ?? "", tags);
        }
        const dl = this.data.get(this.defLang);
        if (dl?.has(key)) {
            return this.interpolate(dl.get(key) ?? "", tags);
        }
        console.error("string not found: " + key);
        return key;
    }

    interpolate(text: string, tags: { [k: string]: string }): string {
        for (const [key, value] of Object.entries(tags)) {
            text = text.replace(`{${key}}`, value);
        }
        return text;
    }

    /**
     * Load a lot of translation data at once.
     * @param lang The language code (E.g. "en", "ja")
     * @param data Key value translation data
     */
    loadData(lang: Language, data: Record<string, string>): void {
        let md: Map<string, string> = this.data.get(lang) ?? new Map();
        if (!this.data.has(lang)) {
            this.data.set(lang, md);
        }
        for (const key in data) {
            md.set(key, data[key]);
        }
    }

    getDisplayNameOrder(): string {
        switch (this.lang) {
            case "ja":
                return ":last :first";
            default:
                return ":first :last";
        }
    }
}

import { ReactElement, createContext, useEffect, useState } from "react";
import { UserDetailsResponse, getUserDetails } from "../api";
import { Outlet } from "react-router-dom";

export const UserContext = createContext<UserDetailsResponse | null>(null);

export const UserContextProvider = (): ReactElement => {
    const [details, setDetails] = useState<UserDetailsResponse | null>(null);
    useEffect(() => {
        getUserDetails().then(response => {
            setDetails(response);
        });
    }, []);

    return (
        <UserContext.Provider value={details}>
            <Outlet />
        </UserContext.Provider>
    );
};

import { MenuUserData } from "@colorkrew/design-system";
import { AutocompleteTag, TokenLimit, User } from "../api";
import { trans } from "./trans";
import { SuspendDateType } from "../components/Admin/SuspendServiceSettingsModal";
import { DateTime } from "luxon";

export const getFileSizeInMb = (bytes: number) => {
    const kb = bytes / 1024;
    const mb = kb / 1024;
    if (mb >= 0.01) {
        return mb.toFixed(2) + "MB";
    } else {
        return kb.toFixed(2) + "KB";
    }
};

export const formatNumber = (num: number) => {
    return num.toLocaleString(trans.getLang(), { useGrouping: true });
};

export const getUserDisplayName = (firstName: string | undefined, lastName: string | undefined, fallbackValue = ""): string => {
    const firstNameString = firstName ?? "";
    const lastNameString = lastName ?? "";

    if (!firstNameString && !lastNameString) {
        return fallbackValue;
    }

    if (!lastNameString) {
        return firstNameString;
    }
    if (!firstNameString) {
        return lastNameString;
    }

    const nameOrder = trans.getDisplayNameOrder();
    return nameOrder.replace(":first", firstNameString).replace(":last", lastNameString);
};

export const getProfileUser = (user: User | undefined, tenantName: string | undefined): MenuUserData | undefined => {
    if (!user) {
        return;
    }
    return {
        avatarURL: user?.avatar_url ?? undefined,
        name: getUserDisplayName(user?.first_name ?? null, user?.last_name ?? null),
        email: user?.email,
        tenantName: tenantName ?? "",
        profileLink: user?.profile_url,
        logoutLink: "/logout"
    };
};

export const getDateTimeFromSuspendDateType = (date: SuspendDateType): string => {
    let formattedDate = DateTime.utc();
    switch (date) {
        case SuspendDateType.TOMORROW:
            formattedDate = formattedDate.plus({ days: 1 });
            break;
        case SuspendDateType.NEXT_WEEK:
            formattedDate = formattedDate.plus({ days: 7 });
            break;
        case SuspendDateType.NEXT_MONTH:
            formattedDate = formattedDate.startOf("month").plus({ months: 1 });
            break;
        case SuspendDateType.END_OF_TIME:
            return "9999-12-31T23:59:59Z";
    }
    return formattedDate.startOf("second").toISO({ suppressMilliseconds: true }) ?? "";
};

export const isDateInFuture = (suspendedUntil?: string | null): boolean => {
    if (!suspendedUntil) {
        return false;
    }
    return DateTime.utc() < DateTime.fromISO(suspendedUntil);
};

import { PropsWithChildren, ReactElement } from "react";
import styles from "./TextModal.module.css";
import { Button, useDesignSystem } from "@colorkrew/design-system";
import { trans } from "../../util/trans";

type Props = {
    header: string;
    handleClose: () => void;
};

const TextModal = ({ children, header, handleClose }: PropsWithChildren<Props>): ReactElement => {
    const { tokens } = useDesignSystem();

    return (
        <div style={{ backgroundColor: tokens.ThemeSurfaceOverlay }} className={styles.container} onClick={handleClose}>
            <div
                style={{ backgroundColor: tokens.ThemeSurfacePrimary }}
                className={styles.content}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <div className={styles.header}>{header}</div>
                <div className={styles.body}>{children}</div>
                <div className={styles.closeButton}>
                    <Button uppercase={false} color="inherit" variant="contained" onClick={handleClose}>
                        {trans.get("close")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default TextModal;
